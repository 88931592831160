import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BannerItem from '../../component/BannerItem/BannerItem'
import useWindowDimensions from '../../useWindowDimensions'
import './style.less'
import { Col, model, Row } from 'antd'
import 'swiper/swiper-bundle.css'
import WOW from 'wowjs'
import { useEffect } from 'react'
import InputText from '../../component/InputText/InputText'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'

import Swiper, {
	Navigation,
	Pagination,
	Autoplay,
	Thumbs,
	EffectFade,
} from 'swiper'
import create from '../../images/destination2/create.png'

const Faq = () => {
	const { t } = useTranslation()
	const { height, width } = useWindowDimensions()

	useEffect(() => {
		window.scroll(0, 0)
		document.title = 'Wyngs - Faq'
		new WOW.WOW({
			live: false,
		}).init()
	}, [])

	return (
		<div>
			<section className='faq_sec  wow fadeIn '>
				<div className='titleContainer'>
					<img className='fluid' src={create} />
					&nbsp;{t('FAQ.title')}{' '}
				</div>
				{/* {width >= 640 ? ( */}
				<>
					<div className='container'>
						<ol type='1'>
							<div className='question'>
								<li>{t('FAQ.question1')}</li>
							</div>

							<div className='answer'>{t('FAQ.answer1')}</div>

							<div className='question'>
								<li>{t('FAQ.question2')}</li>
							</div>
							<div className='answer'>{t('FAQ.answer2')}</div>

							<div className='question'>
								<li>{t('FAQ.question3')}</li>
							</div>
							<div className='answer'>{t('FAQ.answer3')}</div>

							<div className='question'>
								<li>{t('FAQ.question4')}</li>
							</div>
							<div className='answer'>{t('FAQ.answer4')}</div>

							<div className='question'>
								<li>{t('FAQ.question5')}</li>
							</div>
							<div className='answer'>{t('FAQ.answer5')}</div>

							<div className='question'>
								<li>{t('FAQ.question6')}</li>
							</div>
							<div className='answer'>{t('FAQ.answer6')}</div>

							<div className='question'>
								<li>{t('FAQ.question7')}</li>
							</div>
							<div className='answer'>
								<ol type='i'>
									<li> {t('FAQ.l1')}</li>
									<li> {t('FAQ.l2')}</li>
									<li> {t('FAQ.l3')} </li>
								</ol>
							</div>

							<div className='question'>
								<li>{t('FAQ.question8')}</li>
							</div>
							<div className='answer'>{t('FAQ.answer8')}</div>

							<div className='question'>
								<li>{t('FAQ.question9')}</li>
							</div>
							<div className='answer'>{t('FAQ.answer9')}</div>

							<div className='question'>
								<li>{t('FAQ.question10')}</li>
							</div>
							<div className='answer'>
								{/* <ol type='a'> */}
								<div className='sub'>{t('FAQ.item1')}</div>
								<ul>
									<li>{t('FAQ.list1')}</li>
									<li>{t('FAQ.list2')}</li>
									<li>{t('FAQ.list3')}</li>
									<li>{t('FAQ.list4')}</li>
									<li>{t('FAQ.list5')}</li>
									<li>{t('FAQ.list6')}</li>
									<li>{t('FAQ.list7')}</li>
									<li>{t('FAQ.list8')}</li>
									<li>{t('FAQ.list9')}</li>
									<li>{t('FAQ.list10')}</li>
									<li>{t('FAQ.list11')}</li>
									<li>{t('FAQ.list12')}</li>
									<li>{t('FAQ.list13')}</li>
								</ul>
								<div className='sub'>{t('FAQ.item2')}</div>
								<ul>
									<li>{t('FAQ.list14')}</li>
									<li>{t('FAQ.list15')}</li>
									<li>{t('FAQ.list16')}</li>
									<li>{t('FAQ.list17')}</li>
									<li>{t('FAQ.list18')}</li>
									<li>{t('FAQ.list19')}</li>
									<li>{t('FAQ.list20')}</li>
									<li>{t('FAQ.list21')}</li>
									<li>{t('FAQ.list22')}</li>
									<li>{t('FAQ.list23')}</li>
									<li>{t('FAQ.list24')}</li>
									<li>{t('FAQ.list25')}</li>
									<li>{t('FAQ.list26')}</li>
									<li>{t('FAQ.list27')}</li>
									<li>{t('FAQ.list28')}</li>
								</ul>
								{/* </ol> */}
							</div>

							{/* <div className="answer">
						<table class='responsive table' style={{border:'1px solid black ',borderRadius:"2px"}}>
					<tr style={{background:'#fff'}}>
						<td>{t('FAQ.item1')}</td>
						<td>
						<ul>
								<li>{t("FAQ.list1")}</li>
								<li>{t("FAQ.list2")}</li>
								<li>{t("FAQ.list3")}</li>
								<li>{t("FAQ.list4")}</li>
								<li>{t("FAQ.list5")}</li>
								<li>{t("FAQ.list6")}</li>
								<li>{t("FAQ.list7")}</li>
								<li>{t("FAQ.list8")}</li>
								<li>{t("FAQ.list9")}</li>
								<li>{t("FAQ.list10")}</li>
								<li>{t("FAQ.list11")}</li>
								<li>{t("FAQ.list12")}</li>
								<li>{t("FAQ.list13")}</li>
							   </ul>
						</td>
					</tr>
					<tr>
						<td>{t('FAQ.item1')}</td>

						<td>
						<ul>
							<li>{t("FAQ.list14")}</li>
							<li>{t("FAQ.list15")}</li>
							<li>{t("FAQ.list16")}</li>
							<li>{t("FAQ.list17")}</li>
							<li>{t("FAQ.list18")}</li>
							<li>{t("FAQ.list19")}</li>
							<li>{t("FAQ.list20")}</li>
							<li>{t("FAQ.list21")}</li>
							<li>{t("FAQ.list22")}</li>
							<li>{t("FAQ.list23")}</li>
							<li>{t("FAQ.list24")}</li>
							<li>{t("FAQ.list25")}</li>
							<li>{t("FAQ.list26")}</li>
							<li>{t("FAQ.list27")}</li>
							<li>{t("FAQ.list28")}</li>

							</ul>
						</td>
					</tr>
					</table>
						</div> */}

							<div className='question'>
								<li>{t('FAQ.question11')}</li>
							</div>
							<div className='answer'>{t('FAQ.answer11')}</div>

							<div className='question'>
								<li>{t('FAQ.question12')}</li>
							</div>
							<div className='answer'>{t('FAQ.answer12')}</div>

							<div className='question'>
								<li>{t('FAQ.question13')}</li>
							</div>
							<div className='answer'>{t('FAQ.answer13')}</div>

							<div className='question'>
								<li>{t('FAQ.question14')}</li>
							</div>
							<div className='answer'>{t('FAQ.answer14')}</div>

							<div className='question'>
								<li>{t('FAQ.question15')}</li>
							</div>
							<div className='answer'>{t('FAQ.answer15')}</div>
						</ol>
					</div>

					{/* </>
				) : (
					<>
			</>
                )}			 */}
				</>
			</section>
		</div>
	)
}

export default Faq
