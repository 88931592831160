import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BannerItem from '../../component/BannerItem/BannerItem'
import useWindowDimensions from '../../useWindowDimensions'
import banner from '../../images/feedbackCenter/banner.png'
import './style.less'
import InputText from '../../component/InputText/InputText'
import Button from '../../component/Button/Button'
import WOW from 'wowjs'
import { useEffect } from 'react'
import mobile834 from '../../images/feedbackCenter/mobile834.png'
import FooterContactUsItem from '../../component/FooterContactUsItem/FooterContactUsItem'
import axios from 'axios'
import urldata from '../../urldata'

const DisputeCenter = (props) => {
	const { t } = useTranslation()
	const { height, width } = useWindowDimensions()

	const [name, setName] = useState('')
	const [nameerr, setNameerr] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [phoneNumbererr, setPhoneNumbererr] = useState('')
	const [trackingNumber, setTrackingNumber] = useState('')

	const [message, setMessage] = useState('')
	const [messageerr, setMessageErr] = useState('')
	const [companyType, setCompanyType] = useState('')
	const [complainerType, setcomplainerType] = useState('')

	useEffect(() => {
		window.scroll(0, 0)
		document.title = 'Wyngs - Feedback Center'
		new WOW.WOW({
			live: false,
		}).init()
	}, [])

	const submitBtnClick = async () => {
		const data = {
			name,
			phoneNumber,
			trackingNumber,
			message,
			companyType,
			complainerType,
		}

		try {
			const data = await axios.post(urldata + 'user/addFeedback', {
				name,
				phoneNumber,
				trackingNumber,
				message,
				complaintType: companyType,
				complainer: complainerType,
			})
			setName('')
			setPhoneNumber('')
			setTrackingNumber('')
			setMessage('')
			setTrackingNumber('')
			setCompanyType('')
			document.getElementById('Who are you?').value = ''
			document.getElementById('Who are you2?').value = ''
		} catch (error) {
			alert(error?.response?.data?.msg || 'Something went wrong')
		}
	}

	return (
		<>
			<BannerItem
				img={width > 835 ? banner : mobile834}
				title={t('DisputeCenter.title')}
				color='white'
			/>
			<section className='dc_sec1 wow fadeIn d-f j-c f-c a-c'>
				<div className='card'>
					<div className='title'>{t('DisputeCenter.title')}</div>

					<div className='formContainer w-f'>
						<div className='mt-4'>
							<InputText
								value={name}
								onChange={(e) => setName(e.target.value)}
								title={t('DisputeCenter.name')}
							/>
							<div className='error'>{nameerr}</div>
						</div>
						<div className='mt-4'>
							<InputText
								value={phoneNumber}
								onChange={(e) => setPhoneNumber(e.target.value)}
								title={t('DisputeCenter.Contact number')}
							/>
							<div className='error'>{phoneNumbererr}</div>
						</div>
						<div className='mt-4'>
							<InputText
								value={trackingNumber}
								onChange={(e) => setTrackingNumber(e.target.value)}
								title={t('DisputeCenter.Tracking number')}
							/>
						</div>
						<div className='mt-4'>
							<InputText
								dropdown
								list={[
									{
										name: '',
										value: '',
										notselected: true,
									},
									{
										name: 'Merchant',
										value: 'Merchant',
									},
									{
										name: 'Lender',
										value: 'Lender',
									},
									{
										name: 'Others',
										value: 'Others',
									},
								]}
								onChange={(e) => setCompanyType(e.target.value)}
								value={companyType}
								title={t('DisputeCenter.Complaint type')}
								id='Who are you?'
							/>
						</div>

						<div className='mt-4'>
							<InputText
								dropdown
								list={[
									{
										name: '',
										value: '',
										notselected: true,
									},
									{
										name: 'Merchant',
										value: 'Merchant',
									},
									{
										name: 'Lender',
										value: 'Lender',
									},
									{
										name: 'Others',
										value: 'Others',
									},
								]}
								onChange={(e) => setcomplainerType(e.target.value)}
								title={t('DisputeCenter.Complainer')}
								id='Who are you2?'
							/>
						</div>
						<div className='mt-4'>
							<InputText
								value={message}
								limit
								onChange={(e) => setMessage(e.target.value)}
								title={t('Message (upto 100 character')}
							/>
							<div className='error'>{messageerr}</div>
						</div>
						<div className='submitbtnContainer'>
							<Button orange title='SUBMIT' onClick={submitBtnClick} />
						</div>
					</div>
				</div>
			</section>

			<FooterContactUsItem
				title='Shipping Consultation'
				btnTxt='Enquire'
				color='orange'
			/>
		</>
	)
}

export default DisputeCenter
