import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import BannerItem from '../../component/BannerItem/BannerItem'
import useWindowDimensions from '../../useWindowDimensions'
import introduction from '../../images/aboutus/introduction.png'

import banner from '../../images/careers/banner.png'
import Intro from '../../images/careers/Intro.png'
import sec1logo1 from '../../images/careers/sec1logo1.svg'
import sec1logo2 from '../../images/careers/sec1logo2.svg'
import sec1logo3 from '../../images/careers/sec1logo3.svg'
import search from '../../images/careers/search.png'
import urldata, { Imageurldata } from '../../urldata'
import axios from 'axios'

import mobile834 from '../../images/careers/mobile834.png'

import ReactPaginate from 'react-paginate'
import WOW from 'wowjs'
import parse from 'html-react-parser'

import './style.less'
import { Col, Row } from 'antd'
import Button from '../../component/Button/Button'
import InputText from '../../component/InputText/InputText'

import { Modal } from 'react-bootstrap'
import growth from '../../images/careers/growth.png'
import FooterContactUsItem from '../../component/FooterContactUsItem/FooterContactUsItem'

const Careers = (props) => {
	const { t } = useTranslation()
	const { height, width } = useWindowDimensions()

	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const [applyShow, setApplyShow] = useState(false)
	const [descShow, setDescShow] = useState(false)
	const [jobDescriptions, setJobDescriptions] = useState([])
	const [jobDescInView, setJobDescInView] = useState('')
	const [jobApplyInView, setJobApplyInView] = useState('')
	const [loading, setloading] = useState(false)
	const [dropCvFileName, setDropCvFileName] = useState('')
	const [dropApplyFileName, setDropApplyFileName] = useState('')

	const [name, setname] = useState('')
	const [nameerr, setnameerr] = useState('')
	const [emailId, setemailId] = useState('')
	const [emailIderr, setemailIderr] = useState('')

	const [phoneNumber, setPhoneNumber] = useState('')
	const [phoneNumbererr, setPhoneNumbererr] = useState('')
	const [fileerr, setFileerr] = useState('')
	const [companyName, setcompanyName] = useState('')
	const [subject, setsubject] = useState('')
	const [cvapplyFile, setCvapplyFile] = useState([])
	const [nofileErr, setNofileErr] = useState('')

	const [totalJobDescCount, setTotalJobDescCount] = useState(12)
	const [currJobDescPage, setCurrJobDescPage] = useState(1)

	const [fileSizealert, setFileSizealert] = useState('')

	const [showApplySuccesstxt, setShowApplySuccesstxt] = useState(false)

	const [keywordSearch, setKeywordSearch] = useState('')
	const [totalPage, setTotalPage] = useState(0)
	const [selectedPage, setSelectedPage] = useState(0)
	const [totalPageArray, setTotalPageArray] = useState([])
	const [inSearch, setInSearch] = useState(false)

	const [selectedArray, setSelectedArray] = useState([])

	const [pgLoading, setPgLoading] = useState(true)

	const [jobPostList, setJobPostList] = useState([])

	const [itemOffset, setItemOffset] = useState(0)
	const [currentItems, setCurrentItems] = useState([])
	const [pageCount, setPageCount] = useState(0)
	const [applyData, setAppliedData] = useState({})
	const itemsPerPage = 3

	// Invoke when user click to request another page.
	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % jobPostList.length

		const endOffset = newOffset + itemsPerPage
		const d = jobPostList.slice(newOffset, endOffset)

		setCurrentItems([...d])

		setItemOffset(newOffset)
	}

	useEffect(() => {
		if (!applyShow) {
			setDropApplyFileName('')
			setFileSizealert('')
			setnameerr('')
			setemailIderr('')
			setPhoneNumbererr('')
			setNofileErr('')
		}
	}, [applyShow])

	const handleApplyShow = (item) => {
		setJobApplyInView(item.title)
		setAppliedData(item)
		setApplyShow(true)
	}
	const handleJobSearch = (evt) => {
		evt.preventDefault()
		setKeywordSearch(evt.target.value)

		const newOffset = (0 * itemsPerPage) % jobPostList.length

		const endOffset = newOffset + itemsPerPage
		let filteredData = [...jobPostList]

		if (evt.target.value !== '') {
			filteredData = filteredData.filter((item) =>
				item.designation.toLowerCase().includes(evt.target.value.toLowerCase())
			)
		}

		const d = filteredData.slice(newOffset, endOffset)

		setCurrentItems([...d])

		setItemOffset(newOffset)
	}

	useEffect(() => {
		//fetchJobDesc(currJobDescPage);
		window.scroll(0, 0)
		setPgLoading(true)
		document.title = 'Wyngs - Careers'
		new WOW.WOW({
			live: false,
		}).init()
		getJobPost()
	}, [])

	const getJobPost = () => {
		setPgLoading(true)
		let url = new URL(urldata + 'user/jobPost')

		axios({
			method: 'get',
			url: url,
			withCredentials: true,
		})
			.then((response) => {
				//console.log("added", response.data.data);
				const list = response.data.data
				list.reverse()

				setJobPostList(list)

				const endOffset = itemOffset + itemsPerPage
				const d = list.slice(itemOffset, endOffset)
				setCurrentItems([...d])
				setPageCount(Math.ceil(list.length / itemsPerPage))

				setPgLoading(false)
				try {
					document.getElementById('paginationLeftarrow').style.opacity = '.5'
					document.getElementById('paginationLeftarrow').style.cursor =
						'initial'
				} catch (error) {}
			})
			.catch((error) => {
				//console.log(error);
				//showToast('danger')
				setPgLoading(false)
				//alert("Something Went Wrong2")
			})
	}

	const handleDescClose = () => setDescShow(false)
	const handleDescShow = (description) => {
		setJobDescInView(description)

		setDescShow(true)
	}
	const handleApplyClose = () => {
		setAppliedData({})
		setApplyShow(false)
	}

	const handleJobApplyFileChosen = (e) => {
		if (!e.target.files[0]) return
		setFileerr('')

		let fileName = e.target.files[0].name
		let dot = fileName.lastIndexOf('.') + 1
		var extFile = fileName.substr(dot, fileName.length).toLowerCase()
		setDropApplyFileName(e.target.files[0].name)
		setNofileErr('')
		if (!['pdf', 'doc', 'docx'].includes(extFile)) {
			setFileSizealert('File format not valid')
			return
		}

		if (e.target.files[0].size > 1000000) {
			setFileSizealert('file size more than 1 MB!')
			setFileerr('')
			setNofileErr('')
		} else setFileSizealert('')
	}

	// useEffect(() => {
	// 	let pagestart = selectedPage * 3
	// 	let temp = [...jobPostList]
	// 	setSelectedArray(temp.slice(pagestart, pagestart + 3))
	// 	setTotalPage(Math.ceil(jobPostList.length / 3))
	// 	leftArrowClickOpacityHandle()
	// 	rightArrowClickOpacityHandle()
	// }, [selectedPage])

	const openingsList = currentItems.map((item, i) => {
		return (
			<div key={i} className='opening'>
				<div className='opening-left'>
					<div className='title'>{item.designation}</div>
					<div className='desc' onClick={() => handleDescShow(item.desc)}>
						<span className='desc_span'>Job Description</span>{' '}
						<span>
							<i
								className='fa fa-angle-right'
								style={{ paddingLeft: '0.2em', fontWeight: 'bold' }}
							></i>
						</span>
					</div>
				</div>

				<div
					className='opening-right btnContainer'
					onClick={() => handleApplyShow(item)}
				>
					{width > 480 ? (
						<Button loading={loading} title='Apply now' />
					) : (
						<Button noeffect='true' loading={loading} title='Apply now' />
					)}
				</div>
			</div>
		)
	})

	const handleApplySubmit = async (evt) => {
		var formIsValid = true

		if (!name) {
			formIsValid = false
			setnameerr('*Please enter your name.')
		} else {
			setnameerr('')
		}

		if (typeof name !== 'undefined') {
			if (!name.match(/^[a-zA-Z ]*$/)) {
				formIsValid = false
				setnameerr('*Please enter alphabet characters only.')
			}
		}

		if (typeof emailId !== 'undefined') {
			//regular expression for email validation
			var pattern = new RegExp(
				/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
			)
			if (!pattern.test(emailId)) {
				formIsValid = false
				setemailIderr('*Please enter valid email-Id.')
			} else {
				setemailIderr('')
			}
		}

		if (typeof phoneNumber !== 'undefined') {
			if (!phoneNumber.match(/^\d{10,}$/)) {
				formIsValid = false
				setPhoneNumbererr('*Please enter valid mobile number.')
			} else {
				setPhoneNumbererr('')
			}
		}

		if (fileSizealert != '') {
			formIsValid = false
			setFileerr('*Please upload cv')
		}
		if (document.querySelector('#upload').files.length == 0) {
			setNofileErr('*Choose a file')
		}

		let formData = new FormData()
		formData.append('name', name)
		formData.append('job_title', applyData.designation)
		formData.append('_id', applyData._id)
		formData.append('email', emailId)
		formData.append('contact_number', phoneNumber)
		formData.append('company_name', companyName)
		formData.append('career_summary', subject)
		formData.append('resume_file', document.querySelector('#upload').files[0])

		if (formIsValid) {
			try {
				await axios({
					method: 'post',
					url: `${urldata}user/job-apply`,
					data: formData,
				})

				setname('')
				setemailId('')
				setsubject('')
				setPhoneNumber('')
				setcompanyName('')
				setCvapplyFile('')
				setShowApplySuccesstxt(true)
				setTimeout(() => {
					setShowApplySuccesstxt(false)
					setApplyShow(false)
					setAppliedData({})
					document.querySelector('#upload').value = null
					setDropCvFileName('')
				}, 2000)
			} catch (error) {
				alert('Something Went Wrong')
			}
		}
	}

	if (pgLoading) {
		return <div>Loading...</div>
	}

	return (
		<>
			<BannerItem
				img={width > 835 ? banner : mobile834}
				title={t('Careers.title')}
				color='white'
			/>

			<section className='about_sec1 carrers_sec1 wow fadeIn d-f j-c a-c '>
				<div className='container'>
					<Row>
						<Col lg={12} className='d-f'>
							<div className='contentWrapper'>
								<div className='content'>
									<span className='textWrapper'>
										<span>
											{' '}
											<img
												className='fluid'
												src={introduction}
												alt='introduction'
											/>
											&nbsp;&nbsp;{t('about.desc')}
										</span>
									</span>
									<div className='title'>{t('Careers.title1')}</div>
									<p>{t('Careers.desc')}</p>
								</div>
							</div>
						</Col>
						<Col lg={12} md={24} className=''>
							<div className='imgcontainer'>
								<img src={Intro} className='fluid' />
							</div>
						</Col>
					</Row>
				</div>
			</section>
			<section>
				<img src={growth} className='fluid' />
			</section>
			{/* <section
				className='newsec2 wow fadeInUp'
				data-wow-duration='1.5s'
				data-wow-delay='0.5s'
			>
				<div className='title'>{t('Careers.sec2Title')}</div>
				<div className='desc'>{t('Careers.sec2Desc')}</div>
				<div className='flex_wrapper'>
					<div className='boxx'>
						<div className='card_logo'>
							<img src={sec1logo1} className='card_img' />
						</div>
						<div className='card_text_wrapper'>
							<div className='text_title'>{t('Careers.sec2lt1')}</div>
							<div className='text_desc'>{t('Careers.sec2desc1')}</div>
						</div>
					</div>
					<div className='boxx middle_boxx'>
						<div className='card_logo'>
							<img src={sec1logo2} className='card_img' />
						</div>

						<div className='card_text_wrapper'>
							<div className='text_title'>{t('Careers.sec2lt2')}</div>
							<div className='text_desc'>{t('Careers.sec2desc2')}</div>
						</div>
					</div>
					<div className='boxx'>
						<div className='card_logo'>
							<img src={sec1logo3} className='card_img' />
						</div>

						<div className='card_text_wrapper'>
							<div className='text_title'>{t('Careers.sec2lt3')}</div>
							<div className='text_desc'>{t('Careers.sec2desc3')}</div>
						</div>
					</div>
				</div>
			</section> */}

			<section
				className='careersec6 wow fadeInUp'
				data-wow-duration='1.5s'
				data-wow-delay='0.5s'
			>
				<div className='m-0 opening-head openings-container wow fadeInUp'>
					<div className='firstcol'>
						<div className='working-title'>{t('Careers.opening')}</div>
						<div className='working-desc'>
							<div className='working-desc-div'>
								<img src={search} alt='' className='fluid' />
								<input
									onChange={handleJobSearch}
									type='text'
									value={keywordSearch}
									placeholder='Search jobs, titles, keywords'
								/>
							</div>
						</div>
					</div>
				</div>

				<Row className='row_opening'>
					<div className='openings-cont'>
						{openingsList}
						{pageCount == 0 && <div className='no-jobs'>No jobs found</div>}
					</div>

					{keywordSearch == '' && (
						<div className='pagination_container'>
							{pageCount >= 3 && (
								<ReactPaginate
									previousLabel={
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='8.86'
											height='14.6'
											viewBox='0 0 8.86 14.6'
											className={1 == currJobDescPage ? 'disabled' : ''}
										>
											<path
												id='Icon_awesome-chevron-right'
												data-name='Icon awesome-chevron-right'
												d='M2.168,10.515l6.5,6.5a.8.8,0,0,0,1.135,0l.758-.758a.8.8,0,0,0,0-1.133L5.41,9.948l5.15-5.174a.8.8,0,0,0,0-1.133L9.8,2.883a.8.8,0,0,0-1.135,0l-6.5,6.5A.8.8,0,0,0,2.168,10.515Z'
												transform='translate(-1.933 -2.648)'
												fill='#4c4c4c'
											/>
										</svg>
									}
									nextLabel={
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='8.86'
											height='14.6'
											viewBox='0 0 8.86 14.6'
											className={
												Math.ceil(totalJobDescCount / 5) == currJobDescPage
													? 'disabled'
													: ''
											}
										>
											<path
												id='Icon_awesome-chevron-right'
												data-name='Icon awesome-chevron-right'
												d='M10.558,10.515l-6.5,6.5a.8.8,0,0,1-1.135,0l-.758-.758a.8.8,0,0,1,0-1.133L7.316,9.948,2.167,4.774a.8.8,0,0,1,0-1.133l.758-.758a.8.8,0,0,1,1.135,0l6.5,6.5A.8.8,0,0,1,10.558,10.515Z'
												transform='translate(-1.933 -2.648)'
												fill='#4c4c4c'
											/>
										</svg>
									}
									breakLabel={'...'}
									breakClassName={'break-me'}
									onPageChange={handlePageClick}
									pageCount={pageCount}
									containerClassName={'pagination'}
									subContainerClassName={'pages pagination'}
									activeClassName={'active'}
								/>
							)}
						</div>
					)}
				</Row>
			</section>
			<Modal
				show={descShow}
				onHide={handleDescClose}
				className='job-desc-modal'
				centered
				scrollable='true'
			>
				<Modal.Header closeButton>
					<Modal.Title className='job-desc-head'>Job Description</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='scrollbar scrollbar-primary'>
						<h5 className='job-desc-resp-head'>Job Role & Responsibilities</h5>
						<div>
							<p className='job-desc-sub-desc'>{parse(jobDescInView)}</p>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={applyShow}
				onHide={handleApplyClose}
				className='job-apply-modal'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title className='job-apply-head'>Apply Now</Modal.Title>
				</Modal.Header>
				<Modal.Body className='job-apply-body'>
					<div className='job-apply-body-cont'>
						<Row>
							<input type='hidden' name='job_title' value={jobApplyInView} />
							<Col md={12} xs={24} className='pr-1'>
								<InputText
									value={name}
									onChange={(e) => setname(e.target.value)}
									name='name'
									title='Name*'
								/>
								<div className='err'>{nameerr}</div>
							</Col>
							<Col md={12} xs={24}>
								<InputText
									value={emailId}
									onChange={(e) => setemailId(e.target.value)}
									name='email'
									title='Email ID*'
								/>
								<div className='err'>{emailIderr}</div>
							</Col>

							<Col md={12} xs={24} className='pr-1'>
								<InputText
									value={phoneNumber}
									type='tel'
									onChange={(e) => setPhoneNumber(e.target.value)}
									name='contact_number'
									title='Contact Number*'
								/>
								<div className='err'>{phoneNumbererr}</div>
							</Col>

							<Col md={12} xs={24}>
								<InputText
									value={companyName}
									onChange={(e) => setcompanyName(e.target.value)}
									name='company_name'
									title='Company Name'
								/>
							</Col>

							<Col md={24} xs={24}>
								<InputText
									value={subject}
									onChange={(e) => setsubject(e.target.value)}
									name='career_summary'
									title='Career Summary / Message'
								/>
							</Col>

							<Col md={24} xs={24}>
								<div className='resume'>
									<input
										type='file'
										className='cv-file-input'
										accept='.pdf,.doc,.docx'
										id='upload'
										hidden
										name='resume'
										onChange={handleJobApplyFileChosen}
									/>
									<label for='upload'>
										<span className='addFileSpan'>
											{' '}
											{/* <img
												src={upload}
												style={{ width: '1.5em', paddingRight: '0.5em' }}
											/>{' '} */}
											Add File
										</span>
									</label>
									<p>
										{dropApplyFileName ? (
											<div className='dropcvfilenamediv'>
												{dropApplyFileName}
											</div>
										) : (
											<span className='outerSpan'>
												<span className='innerSpan'>Resume*</span> (Upload docx
												file, pdf upto 1 mb only){' '}
											</span>
										)}
									</p>
								</div>
								<span className='err'>{fileSizealert}</span>
								<span className='err'>{nofileErr}</span>
							</Col>
							<Col md={24}>
								<div className='submit jobSubmitDiv'>
									{/* <button type="submit" onClick={handleApplySubmit} className='btnSubmitHover'>
                    Submit
                  </button> */}
									<Button title='Submit' onClick={handleApplySubmit} />
								</div>
								{showApplySuccesstxt && (
									<div className='thankyouTxt'>
										Thank you! We'll get back to you soon
									</div>
								)}
							</Col>
						</Row>
					</div>
				</Modal.Body>
			</Modal>

			<FooterContactUsItem
				title='Shipping Consultation'
				btnTxt='Enquire'
				color='orange'
			/>
		</>
	)
}

export default Careers
