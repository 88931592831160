import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import urldata from '../../../urldata'
import Button from '../../../component/Button/Button'
import { Col, Row, Modal } from 'react-bootstrap'
import InputText from '../../../component/InputText/InputText'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import upload from '../../../images/careers/upload.svg'
import Toast from '../../../component/Toast/Toast'
import close from '../../../images/close.svg'
import check from '../../../images/check.svg'
import loader from '../../loader.svg'
import './style.less'
const expinyearsOptions = [
	{
		name: '',
	},
	{
		name: 'Months',
	},
	{
		name: 'Years',
	},
]

const ReviewerList = (props) => {
	const [loading, setLoading] = useState(false)
	const [list, setList] = useState([])
	const [modalShow, setModalShow] = useState(false)
	const [show, setShow] = useState(false)
	const [exptabshow, setExptabshow] = useState(false)
	const [designation, setDesignation] = useState('')
	const [designationerr, setDesignationerr] = useState('')
	const [location, setLocation] = useState('')
	const [locationerr, setLocationErr] = useState('')
	const [desc, setDesc] = useState('')
	const [descerr, setDescErr] = useState('')
	const [skills, setSkills] = useState('')
	const [skillserr, setSkillsErr] = useState('')
	const [minexp, setMinexp] = useState('0')
	const [experr, setexpErr] = useState('')
	const [minexperr, setMinexpErr] = useState('')
	const [maxexp, setMaxexp] = useState('0')
	const [maxexperr, setMaxexpErr] = useState('')
	const [expinyears, setExpinyears] = useState('')
	const [expinyearserr, setExpinyearsErr] = useState('')

	const [updateModel, setUpdateModel] = useState(false)
	const [jobId, setjobId] = useState('')

	const [toastlist, setToastList] = useState([])

	let toastProperties = null

	const handleClose = () => setShow(false)
	const handleShow = () => {
		setUpdateModel(false)
		setExptabshow(false)
		setDesignation('')
		setLocation('')
		setDesc('')
		setSkills('')
		setMinexp('')
		setMaxexp('')
		setExpinyears('')
		setDescErr('')
		setDesignationerr('')
		setExpinyearsErr('')
		setLocationErr('')
		setMaxexpErr('')
		setMinexpErr('')
		setSkillsErr('')
		setexpErr('')
		setShow(true)
	}

	const handleShowForUpdate = (rowData, isActive = true) => {
		console.log(rowData)
		let url = new URL(urldata + 'admin/updateRating')
		axios({
			method: 'patch',
			url: url,
			data: { isActive, _id: rowData._id },
			withCredentials: true,
		})
			.then((response) => {
				getList()
			})
			.catch((error) => {
				// showToast("danger");
				setLoading(false)
			})
	}

	const getList = async () => {
		setLoading(true)
		let url = new URL(urldata + 'admin/getRating')
		axios({
			method: 'get',
			url: url,
			withCredentials: true,
		})
			.then((response) => {
				const templist = response.data.data
				setList(templist)
				setLoading(false)
			})
			.catch((error) => {
				// showToast("danger");
				setLoading(false)
			})
	}

	useEffect(() => {
		getList()
	}, [])

	const deleteBtnClick = (_id) => {
		// alert(id);
		setLoading(true)

		let url = new URL(urldata + 'admin/deleteRating')

		axios({
			method: 'delete',
			url: url,
			data: { _id },
			withCredentials: true,
		})
			.then((response) => {
				getList()
				//showToast('delete')
			})
			.catch((error) => {
				// showToast("danger");

				setLoading(false)
			})
	}

	if (loading) {
		return (
			<div
				style={{
					width: '100%',
					height: '80vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
				id='wrapper'
			>
				<div style={{ width: '10em' }} className='profile-main-loader'>
					<div className='loader'>
						<img src={loader} />
					</div>
				</div>
			</div>
		)
	}
	return (
		<>
			<MaterialTable
				title='Rating List'
				columns={[
					{
						title: 'Name',
						field: 'name',
						width: '20%',
					},

					{
						title: 'Rating',
						field: 'rating',
						width: '20%',
					},

					{
						title: 'FeedBack Text',
						field: 'feedback',
						width: '30%',
					},

					{
						field: 'url',
						width: '30%',
						title: 'Action  ',
						render: (rowData) => (
							<div className='d-f'>
								{rowData.isActive ? (
									<Button
										small={true}
										title='Decline'
										onClick={() => {
											handleShowForUpdate(rowData, false)
										}}
									></Button>
								) : (
									<Button
										small={true}
										title=' Approve'
										onClick={() => {
											handleShowForUpdate(rowData, true)
										}}
									></Button>
								)}
								&nbsp;&nbsp;
								<Button
									small={true}
									title=' Delete'
									onClick={() => {
										deleteBtnClick(rowData._id)
									}}
								></Button>
							</div>
						),
					},
				]}
				data={list}
				options={{
					exportButton: true,
					actionsColumnIndex: -1,
				}}
			/>

			<Toast toastList={toastlist} position='top-right' />
		</>
	)
}

export default ReviewerList
