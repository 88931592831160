import React from 'react'
import './style.css' // You can create this CSS file for styling

const RatingContainer = ({ maxRating = 5, fixedRating }) => {
	const renderStars = () => {
		const stars = []
		for (let i = 1; i <= maxRating; i++) {
			stars.push(
				<span key={i} className={`star ${i <= fixedRating ? 'active' : ''}`}>
					★
				</span>
			)
		}
		return stars
	}

	return <div className='rating-container'>{renderStars()}</div>
}

export default RatingContainer
