import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import BannerItem from '../../component/BannerItem/BannerItem'
import useWindowDimensions from '../../useWindowDimensions'
import banner from '../../images/support/banner.png'
import './style.less'
import { Tabs, Space, Row, Col } from 'antd'
import btn from '../../images/support/btn.svg'
import call from '../../images/support/call.png'
import building from '../../images/support/building.png'
import working from '../../images/support/working.png'
import scan from '../../images/support/scan.webp'
import Button from '../../component/Button/Button'
import WOW from 'wowjs'
import mobile834 from '../../images/support/mobile834.png'
import FooterContactUsItem from '../../component/FooterContactUsItem/FooterContactUsItem'

const Support = (props) => {
	const { t } = useTranslation()
	const { height, width } = useWindowDimensions()

	useEffect(() => {
		window.scroll(0, 0)
		document.title = 'Wyngs - Support'
		new WOW.WOW({
			live: false,
		}).init()
	}, [])

	return (
		<>
			<BannerItem
				img={width > 835 ? banner : mobile834}
				title={t('support.title')}
				style={{ Text: 'center' }}
				color='white'
			/>
			<section className='support_sec1 wow fadeIn d-f '>
				<div className='detailsContainer'>
					{width >= 1024 ? (
						<>
							<Tabs tabPosition='left'>
								<Tabs.TabPane
									tab={
										<span className='tab_text'>
											<img className='logo fluid' src={btn} />
											&nbsp;&nbsp; {t('support.tab1')}{' '}
										</span>
									}
									key='tab1'
								>
									<div className='tab_con'>
										<div className='title'> {t('support.t1title')}</div>

										<p className=' sub-content'>{t('support.t1sub')}</p>
										<div className='sub-heading'>
											{' '}
											{t('support.t1heading')}{' '}
										</div>
										<br />
										<div className='content'>
											<p> {t('support.t1p1')}</p>
											<p> {t('support.t1p2')}</p>
										</div>
										<div className='title1'> {t('support.t1title1')}</div>
										<div className='sub-content'>{t('support.t1sub1')}</div>

										<div className='d-f sub-heading'>
											{t('support.t1heading1')}
										</div>
										<br />

										<div className='content'>
											<p>{t('support.t1p3')}</p>
											<p>{t('support.t1p4')} </p>

											<ol type='i'>
												<li> {t('support.t1l1')}</li>
												<li> {t('support.t1l2')}</li>
												<li> {t('support.t1l3')} </li>
												<li> {t('support.t1l4')}</li>
												<li> {t('support.t1l5')}</li>
												<li> {t('support.t1l6')}</li>
											</ol>

											<p>{t('support.t1p5')} </p>
											<p>{t('support.t1p6')} </p>
										</div>
										<div className='dis_title'> {t('support.t1title2')} </div>
										<div className='dis'>{t('support.t1desc2')}</div>
									</div>
								</Tabs.TabPane>

								<Tabs.TabPane
									tab={
										<span className='tab_text'>
											<img className='logo fluid' src={btn} /> &nbsp;&nbsp;
											{t('support.tab2')}
										</span>
									}
									key='tab2'
								>
									<div className='tab_con'>
										<div className='title'>{t('support.tab2')} </div>
										<div className='sub-content'>
											<ul>
												<li>{t('support.tab2l1')}</li>
												<li>{t('support.tab2l2')}</li>
												<li>{t('support.tab2l3')}</li>
												<li>{t('support.tab2l4')}</li>
												<li>{t('support.tab2l5')}</li>
											</ul>
										</div>
										<p className='dis'>
											<span className='bold'>{t('support.tab2title')}</span>
											{t('support.tab2desc')}
										</p>

										<div className='sub-heading1'>
											{t('support.tab2heading1')}
										</div>

										<div className='sub-heading'>
											{t('support.tab2heading2')}
										</div>

										<div className='sub-content'>
											<p>{t('support.tab2p1')}</p>
											<p>{t('support.tab2p2')}</p>
											<p>{t('support.tab2p3')}</p>
										</div>
										<div className='sub-heading'>
											{t('support.tab2heading3')}
										</div>
										<div className='sub-content'>
											<p>{t('support.tab2p4')}</p>
											<p>{t('support.tab2p5')}</p>
											<p>{t('support.tab2p6')}</p>
										</div>
										<div className='sub-heading'>{t('support.tab2p1')}</div>
										<div className='sub-content'>
											<p>{t('support.tab2p7')}</p>
											<p>{t('support.tab2p8')}</p>
											<p>{t('support.tab2p9')}</p>
											<p>{t('support.tab2p10')}</p>
										</div>

										<div className='sub-heading'>
											{t('support.tab2heading5')}
										</div>
										<div className='sub-content'>
											<p>{t('support.tab2p11')}</p>
											<p>{t('support.tab2p12')}</p>
										</div>

										<div className='sub-heading1'>
											{t('support.tab2heading6')}
										</div>

										<div className='buttoncontainer'>
											<div className='blueCard'>
												<div className='txt'>{t('support.tab2b1')}</div>
											</div>
											<div className='blueCard'>
												<div className='txt'>{t('support.tab2b2')}</div>
											</div>
											<div className='blueCard'>
												<div className='txt'>{t('support.tab2b3')}</div>
											</div>
											<div className='blueCard'>
												<div className='txt'>{t('support.tab2b4')}</div>
											</div>
											<div className='blueCard'>
												<div className='txt'>{t('support.tab2b5')}</div>
											</div>
											<div className='blueCard'>
												<div className='txt'>{t('support.tab2b6')}</div>
											</div>
											<div className='blueCard'>
												<div className='txt'>{t('support.tab2b7')}</div>
											</div>
											<div className='blueCard'>
												<div className='txt'>{t('support.tab2b8')}</div>
											</div>
											<div className='blueCard'>
												<div className='txt'>{t('support.tab2b9')}</div>
											</div>
											<div className='blueCard'>
												<div className='txt'>{t('support.tab2b10')}</div>
											</div>
										</div>
									</div>
								</Tabs.TabPane>
								<Tabs.TabPane
									tab={
										<span className='tab_text'>
											<img className='logo fluid' src={btn} /> &nbsp;&nbsp;
											{t('support.tab3')}
										</span>
									}
									key='tab3'
								>
									<div className='tab_con'>
										<div className='title'>{t('support.tab3')} </div>
										<br />

										<div className='sub-heading'>
											{t('support.tab3heading')}{' '}
										</div>
										<br />

										<div className='listContainer '>
											<div className='d-f  a-c'>
												<div className='redBlockContainer'>
													<div className='txt'>{t('support.STEP1')}</div>
												</div>
												<div className='text'>{t('support.tab3desc1')}</div>
											</div>

											<div className='d-f mt   a-c'>
												<div className='redBlockContainer'>
													<div className='txt'>{t('support.STEP2')}</div>
												</div>
												<div className='text'>{t('support.tab3desc2')}</div>
											</div>

											<div className='d-f mt   a-c'>
												<div className='redBlockContainer'>
													<div className='txt'>{t('support.STEP3')}</div>
												</div>
												<div className='text'>{t('support.tab3desc3')}</div>
											</div>

											<div className='d-f  mt  a-c'>
												<div className='redBlockContainer'>
													<div className='txt'>{t('support.STEP4')}</div>
												</div>
												<div className='text'>{t('support.tab3desc4')}</div>
											</div>
											<div className='d-f mt  a-c'>
												<div className='redBlockContainer'>
													<div className='txt'>{t('support.STEP5')}</div>
												</div>
												<div className='text'>{t('support.tab3desc5')}</div>
											</div>
										</div>
										<br />

										<div className='sub-heading'>
											{t('support.tab3heading1')}{' '}
										</div>
										<br />
										<div className='listContainer'>
											<div className='d-f  a-c'>
												<div className='redBlockContainer'>
													<div className='txt'>{t('support.STEP6')}</div>
												</div>
												<div className='text'>{t('support.tab3desc6')}</div>
											</div>
											<div className='d-f mt  a-c'>
												<div className='redBlockContainer'>
													<div className='txt'>{t('support.STEP7')}</div>
												</div>
												<div className='text'>{t('support.tab3desc7')}</div>
											</div>
											<div className='d-f mt  a-c'>
												<div className='redBlockContainer'>
													<div className='txt'>{t('support.STEP8')}</div>
												</div>
												<div className='text'>{t('support.tab3desc8')}</div>
											</div>
											<br />
										</div>

										{/* <p className='text m-4'><span className='btn'>{t('support.STEP6')}</span>&nbsp;{t('support.tab3desc6')} </p><br/>
            <p className='text m-4'><span className='btn'>{t('support.STEP7')}</span>&nbsp; {t('support.tab3desc7')} </p><br/>
            <p className='text m-4'><span className='btn'>{t('support.STEP8')}</span>&nbsp;  {t('support.tab3desc8')}</p> */}
									</div>
								</Tabs.TabPane>

								<Tabs.TabPane
									tab={
										<span className='tab_text'>
											<img className='logo fluid' src={btn} /> &nbsp;&nbsp;
											{t('support.tab4')}
										</span>
									}
									key='tab'
								>
									<div className='tab_con'>
										<div className='fluid'>
											<div className='title'>{t('support.tab4')} </div>
											<div className='customercontainer'>
												<div className='sub-heading'>
													{t('support.tab4heading')}
												</div>
												<div className='listContainer1'>
													<Row>
														<Col lg={18} sm={12}>
															<div className='d-f'>
																<div className='imgContainer1'>
																	<img className='service fluid' src={call} />
																</div>
																<div className='desc1'>
																	<a href='tel:016-636 2196'>016-636 2196</a>
																</div>
															</div>
															<div className='d-f mt'>
																<div className='imgContainer1'>
																	<img className='service fluid' src={call} />
																</div>
																<div className='desc1'>
																	<a href='tel:03-55257099'>03-55257099</a>
																</div>
															</div>
															<div className='d-f mt'>
																<div className='imgContainer1'>
																	<img
																		className='service fluid'
																		src={building}
																	/>
																</div>
																<div className='desc1'>
																	<p>{t('support.tab4address')}</p>
																</div>
															</div>
															<div className='d-f mt'>
																<div className='imgContainer1'>
																	<img
																		className='service fluid'
																		src={working}
																	/>
																</div>
																<div className='desc1'>
																	<p>{t('support.tab4w1')}</p>
																	<p>{t('support.tab4w2')}</p>
																</div>
															</div>
														</Col>
														<Col lg={6} sm={12}>
															<div className='card'>
																<img className='scan fluid' src={scan} />
															</div>
															<div className='reachbutton'>
																<Button
																	title={t('support.tab4button')}
																></Button>
															</div>
														</Col>
													</Row>
												</div>
											</div>
										</div>
									</div>
								</Tabs.TabPane>
							</Tabs>
						</>
					) : (
						<>
							<div>
								<Tabs>
									<Tabs.TabPane
										tab={
											<span className='tab_text'>
												<img className='logo fluid' src={btn} />
												&nbsp;&nbsp; {t('support.tab1')}{' '}
											</span>
										}
										key='tab1'
									>
										<div className='tab_con'>
											<div className='title'> {t('support.t1title')}</div>

											<p className=' sub-content'>{t('support.t1sub')}</p>
											<div className='sub-heading'>
												{' '}
												{t('support.t1heading')}{' '}
											</div>
											<br />
											<div className='content'>
												<p> {t('support.t1p1')}</p>
												<p> {t('support.t1p2')}</p>
											</div>
											<div className='title1'> {t('support.t1title1')}</div>
											<div className='sub-content'>{t('support.t1sub1')}</div>

											<div className='d-f sub-heading'>
												{t('support.t1heading1')}
											</div>
											<br />

											<div className='content'>
												<p>{t('support.t1p3')}</p>
												<p>{t('support.t1p4')} </p>

												<ol type='i'>
													<li> {t('support.t1l1')}</li>
													<li> {t('support.t1l2')}</li>
													<li> {t('support.t1l3')} </li>
													<li> {t('support.t1l4')}</li>
													<li> {t('support.t1l5')}</li>
													<li> {t('support.t1l6')}</li>
												</ol>

												<p>{t('support.t1p5')} </p>
												<p>{t('support.t1p6')} </p>
											</div>
											<div className='dis_title'> {t('support.t1title2')} </div>
											<div className='dis'>{t('support.t1desc2')}</div>
										</div>
									</Tabs.TabPane>

									<Tabs.TabPane
										tab={
											<span className='tab_text'>
												<img className='logo fluid' src={btn} /> &nbsp;&nbsp;
												{t('support.tab2')}
											</span>
										}
										key='tab2'
									>
										<div className='tab_con'>
											<div className='title'>{t('support.tab2')} </div>
											<div className='sub-content'>
												<ul>
													<li>{t('support.tab2l1')}</li>
													<li>{t('support.tab2l2')}</li>
													<li>{t('support.tab2l3')}</li>
													<li>{t('support.tab2l4')}</li>
													<li>{t('support.tab2l5')}</li>
												</ul>
											</div>
											<p className='dis'>
												<span className='bold'>{t('support.tab2title')}</span>
												{t('support.tab2desc')}
											</p>

											<div className='sub-heading1'>
												{t('support.tab2heading1')}
											</div>

											<div className='sub-heading'>
												{t('support.tab2heading2')}
											</div>

											<div className='sub-content'>
												<p>{t('support.tab2p1')}</p>
												<p>{t('support.tab2p2')}</p>
												<p>{t('support.tab2p3')}</p>
											</div>
											<div className='sub-heading'>
												{t('support.tab2heading3')}
											</div>
											<div className='sub-content'>
												<p>{t('support.tab2p4')}</p>
												<p>{t('support.tab2p5')}</p>
												<p>{t('support.tab2p6')}</p>
											</div>
											<div className='sub-heading'>{t('support.tab2p1')}</div>
											<div className='sub-content'>
												<p>{t('support.tab2p7')}</p>
												<p>{t('support.tab2p8')}</p>
												<p>{t('support.tab2p9')}</p>
												<p>{t('support.tab2p10')}</p>
											</div>

											<div className='sub-heading'>
												{t('support.tab2heading5')}
											</div>
											<div className='sub-content'>
												<p>{t('support.tab2p11')}</p>
												<p>{t('support.tab2p12')}</p>
											</div>

											<div className='sub-heading1'>
												{t('support.tab2heading6')}
											</div>

											<div className='buttoncontainer'>
												<div className='blueCard'>
													<div className='txt'>{t('support.tab2b1')}</div>
												</div>
												<div className='blueCard'>
													<div className='txt'>{t('support.tab2b2')}</div>
												</div>
												<div className='blueCard'>
													<div className='txt'>{t('support.tab2b3')}</div>
												</div>
												<div className='blueCard'>
													<div className='txt'>{t('support.tab2b4')}</div>
												</div>
												<div className='blueCard'>
													<div className='txt'>{t('support.tab2b5')}</div>
												</div>
												<div className='blueCard'>
													<div className='txt'>{t('support.tab2b6')}</div>
												</div>
												<div className='blueCard'>
													<div className='txt'>{t('support.tab2b7')}</div>
												</div>
												<div className='blueCard'>
													<div className='txt'>{t('support.tab2b8')}</div>
												</div>
												<div className='blueCard'>
													<div className='txt'>{t('support.tab2b9')}</div>
												</div>
												<div className='blueCard'>
													<div className='txt'>{t('support.tab2b10')}</div>
												</div>
											</div>
										</div>
									</Tabs.TabPane>

									<Tabs.TabPane
										tab={
											<span className='tab_text'>
												<img className='logo fluid' src={btn} /> &nbsp;&nbsp;
												{t('support.tab3')}
											</span>
										}
										key='tab3'
									>
										<div className='tab_con'>
											<div className='title'>{t('support.tab3')} </div>
											<br />

											<div className='sub-heading'>
												{t('support.tab3heading')}{' '}
											</div>
											<br />

											<div className='listContainer'>
												<div className='d-f'>
													<div className='redBlockContainer'>
														<div className='txt'>{t('support.STEP1')}</div>
													</div>
													<div className='text'>{t('support.tab3desc1')}</div>
												</div>
												<div className='d-f mt'>
													<div className='redBlockContainer'>
														<div className='txt'>{t('support.STEP2')}</div>
													</div>
													<div className='text'>{t('support.tab3desc2')}</div>
												</div>
												<div className='d-f mt'>
													<div className='redBlockContainer'>
														<div className='txt'>{t('support.STEP3')}</div>
													</div>
													<div className='text'>{t('support.tab3desc3')}</div>
												</div>

												<div className='d-f mt'>
													<div className='redBlockContainer'>
														<div className='txt'>{t('support.STEP4')}</div>
													</div>
													<div className='text'>{t('support.tab3desc4')}</div>
												</div>
												<div className='d-f mt'>
													<div className='redBlockContainer'>
														<div className='txt'>{t('support.STEP5')}</div>
													</div>
													<div className='text'>{t('support.tab3desc5')}</div>
												</div>
											</div>
											<br />
											{/* <p className='text m-4'>
               &nbsp;{t('support.tab3desc1')} </p><br/>
            <p className='text m-4'><div className='txt'>{t('support.STEP2')}</div>&nbsp; {t('support.tab3desc2')}</p><br/>
            <p className='text m-4'><div className='txt'>{t('support.STEP3')}</div>&nbsp;{t('support.tab3desc3')}</p><br/>
            <p className='text m-4 '><div className='txt'>{t('support.STEP4')}</div>&nbsp; {t('support.tab3desc4')} </p><br/>
            <p className='text m-4 '><div className='txt'>{t('support.STEP5')}</div>&nbsp;{t('support.tab3desc5')}  </p><br/> */}

											<div className='sub-heading'>
												{t('support.tab3heading1')}{' '}
											</div>
											<br />
											<div className='listContainer'>
												<div className='d-f'>
													<div className='redBlockContainer'>
														<div className='txt'>{t('support.STEP6')}</div>
													</div>
													<div className='text'>{t('support.tab3desc6')}</div>
												</div>
												<div className='d-f mt'>
													<div className='redBlockContainer'>
														<div className='txt'>{t('support.STEP7')}</div>
													</div>
													<div className='text'>{t('support.tab3desc7')}</div>
												</div>
												<div className='d-f mt'>
													<div className='redBlockContainer'>
														<div className='txt'>{t('support.STEP8')}</div>
													</div>
													<div className='text'>{t('support.tab3desc8')}</div>
												</div>
											</div>

											{/* <p className='text m-4'><span className='btn'>{t('support.STEP6')}</span>&nbsp;{t('support.tab3desc6')} </p><br/>
            <p className='text m-4'><span className='btn'>{t('support.STEP7')}</span>&nbsp; {t('support.tab3desc7')} </p><br/>
            <p className='text m-4'><span className='btn'>{t('support.STEP8')}</span>&nbsp;  {t('support.tab3desc8')}</p> */}
										</div>
									</Tabs.TabPane>

									<Tabs.TabPane
										tab={
											<span className='tab_text'>
												<img className='logo fluid' src={btn} /> &nbsp;&nbsp;
												{t('support.tab4')}
											</span>
										}
										key='tab'
									>
										<div className='tab_con'>
											<div className='fluid'>
												<div className='title'>{t('support.tab4')} </div>
												<div className='customercontainer'>
													<div className='sub-heading'>
														{t('support.tab4heading')}
													</div>
													<div className='listContainer1'>
														<Row className='j-c'>
															<Col lg={18} sm={12}>
																<div className='d-f'>
																	<div className='imgContainer1'>
																		<img className='service fluid' src={call} />
																	</div>
																	<div className='desc1'>
																		<a href='tel:016-636 2196'>016-636 2196</a>
																	</div>
																</div>
																<div className='d-f mt'>
																	<div className='imgContainer1'>
																		<img className='service fluid' src={call} />
																	</div>
																	<div className='desc1'>
																		<a href='tel:03-55257099'>03-55257099</a>
																	</div>
																</div>
																<div className='d-f mt'>
																	<div className='imgContainer1'>
																		<img
																			className='service fluid'
																			src={building}
																		/>
																	</div>
																	<div className='desc1'>
																		<p>{t('support.tab4address')}</p>
																	</div>
																</div>
																<div className='d-f mt'>
																	<div className='imgContainer1'>
																		<img
																			className='service fluid'
																			src={working}
																		/>
																	</div>
																	<div className='desc1'>
																		<p style={{ paddingLeft: '0' }}>
																			{t('support.tab4w1')}
																		</p>
																		<p style={{ paddingLeft: '0' }}>
																			{t('support.tab4w2')}
																		</p>
																	</div>
																</div>
															</Col>
															<Col lg={6} sm={24}>
																<div className='card'>
																	<img className='scan fluid' src={scan} />
																</div>
																<div className='reachbutton'>
																	<Button title={t('support.tab4button')} />
																</div>
															</Col>
														</Row>
													</div>
												</div>
											</div>
										</div>
									</Tabs.TabPane>
								</Tabs>
							</div>
						</>
					)}
				</div>
			</section>

			<FooterContactUsItem
				title='Shipping Consultation'
				btnTxt='Enquire'
				color='orange'
			/>
		</>
	)
}

export default Support
