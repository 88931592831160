import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import urldata from '../../../urldata'
import { Imageurldata } from '../../../urldata'

import Button from '../../../component/Button/Button'
import { Col, Row, Modal } from 'react-bootstrap'
import InputText from '../../../component/InputText/InputText'

import upload from '../../../images/careers/upload.svg'
import Toast from '../../../component/Toast/Toast'
import close from '../../../images/close.svg'
import check from '../../../images/check.svg'
import loader from '../../loader.svg'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import './style.less'

const expinyearsOptions = [
	{
		name: '',
	},
	{
		name: 'Months',
	},
	{
		name: 'Years',
	},
]

const HomepageBannerList = (props) => {
	const [loading, setLoading] = useState(false)
	const [list, setList] = useState([])
	const [modalShow, setModalShow] = useState(false)
	const [show, setShow] = useState(false)
	const [exptabshow, setExptabshow] = useState(false)
	const [textline1, settextline1] = useState('')
	const [textline1err, settextline1err] = useState('')
	const [textline2, settextline2] = useState('')
	const [textline2err, settextline2Err] = useState('')
	const [textline3, settextline3] = useState('')
	const [textline3err, settextline3Err] = useState('')
	const [link, setlink] = useState('')
	const [linkerr, setlinkErr] = useState('')

	const [bannerimg, setbannerimg] = useState('')
	const [bannerimgerr, setbannerimgErr] = useState('')
	const [bannerimgnotpresent, setbannerimgnotpresent] = useState('')

	const [mbbannerimg, setmbbannerimg] = useState('')
	const [mbbannerimgerr, setmbbannerimgErr] = useState('')
	const [mbImgNotPresent, setmbImgNotPresent] = useState('')

	const [updateModel, setUpdateModel] = useState(false)
	const [showMobImg, setShowMobImg] = useState('')
	const [showDesktopImg, setShowDesktopImg] = useState('')

	const [bannerid, setbannerid] = useState('')

	const [toastlist, setToastList] = useState([])

	let toastProperties = null

	const showToast = (type) => {
		const id = Math.floor(Math.random() * 101 + 1)
		switch (type) {
			case 'success':
				toastProperties = {
					id,
					title: 'Success',
					description: `Banner ${
						updateModel ? 'updated' : 'added'
					} Successfully`,
					backgroundColor: '#329F33',
					icon: check,
				}
				break
			case 'danger':
				toastProperties = {
					id,
					title: 'Something Went Wrong',
					description: '',
					backgroundColor: 'red',
					icon: close,
				}
				break
			case 'delete':
				toastProperties = {
					id,
					title: 'Success',
					description: 'Banner deleted Successfully',
					backgroundColor: '#329F33',
					icon: check,
				}
				break

			default:
				setToastList([])
		}

		setToastList([...toastlist, toastProperties])
	}

	const handleClose = () => setShow(false)
	const handleShow = () => {
		setUpdateModel(false)
		setShow(true)
		setShowMobImg(false)
		setShowDesktopImg(false)

		settextline1('')
		settextline2('')
		settextline3('')
		setlink('')
		setbannerimg('')
		setmbbannerimg('')
	}

	useEffect(() => {
		setLoading(true)

		let url = new URL(urldata + 'admin/getBannerImages')
		axios({
			method: 'get',
			url: url,
			withCredentials: true,
		})
			.then((response) => {
				// console.log(response)
				const templist = response.data.data.reverse()
				setList(templist)
				setLoading(false)
			})
			.catch((error) => {
				setLoading(false)
				//showToast("danger");
			})
	}, [])

	const handleShowForUpdate = (rowData) => {
		setUpdateModel(true)
		setShow(true)
		settextline1(rowData.text1)
		setlink(rowData.link)
		setmbbannerimg(Imageurldata + rowData.mbimgUrl)
		setbannerimg(Imageurldata + rowData.imgUrl)
		setmbImgNotPresent(rowData.mbimgUrl ? false : true)
		setbannerimgnotpresent(rowData.imgUrl ? false : true)

		setbannerid(rowData._id)

		setShowMobImg(false)
		setShowDesktopImg(false)
	}

	const updateBanner = () => {
		var formData = new FormData()
		formData.append('text1', textline1)

		formData.append('link', link)
		formData.append('screen', 'home')

		if (mbImgNotPresent) {
			formData.append('bannerImageMobile', mbbannerimg)
			setmbImgNotPresent(false)
		}

		if (bannerimgnotpresent) {
			formData.append('bannerImageWeb', bannerimg)
			setbannerimgnotpresent(false)
		}

		let url = new URL(urldata + 'admin/updateBannerImages')

		url.searchParams.append('id', bannerid)
		var decoded = decodeURIComponent(url)

		axios({
			method: 'post',
			url: decoded,
			data: formData,
			withCredentials: true,
		})
			.then((response) => {
				const templist = response.data.data.reverse()
				setList(templist)
				showToast('success')
				setShow(false)
			})
			.catch((error) => {
				console.log('error--', error)
				setShow(false)
				// showToast("danger");
			})
	}

	const createbanner = () => {
		var formData = new FormData()
		formData.append('text1', textline1)
		formData.append('link', link)
		formData.append('screen', 'home')
		formData.append('bannerImageMobile', mbbannerimg)
		formData.append('bannerImageWeb', bannerimg)

		let url = new URL(urldata + 'admin/addBannerImages')

		axios({
			method: 'post',
			url: url,
			data: formData,
			withCredentials: true,
		})
			.then((response) => {
				// settextline1("");
				// settextline2("");
				// settextline3("");
				// setlink("");
				// setbannerimg("");
				// setmbbannerimg("");

				const templist = response.data.data.reverse()
				setList(templist)
				showToast('success')
				setShow(false)
			})
			.catch((error) => {
				// showToast("danger");
			})
	}

	const submit = () => {
		var formIsValid = true
		if (!bannerimg) {
			formIsValid = false
			setbannerimgErr('*Please upload desktop banner image.')
		} else {
			setbannerimgErr('')
		}

		if (!mbbannerimg) {
			formIsValid = false
			setmbbannerimgErr('*Please upload mobile  banner image.')
		} else {
			setmbbannerimgErr('')
		}

		// <<<<<<< HEAD
		// if (bannerimg) {
		//   if (bannerimg.size / 1024 > 300) {
		//     formIsValid = false
		//     setbannerimgErr("*File should be less than 300Kb.");
		//   }
		// }
		// if (mbbannerimg) {
		//   if (mbbannerimg.size / 1024 > 300) {
		//     formIsValid = false
		//     setmbbannerimg("*File should be less than 300Kb.");
		//   }
		// }

		// =======
		// >>>>>>> vijay
		if (formIsValid) {
			if (updateModel) {
				updateBanner()
			} else {
				createbanner()
			}
		}
	}

	const deleteBtnClick = (id) => {
		setLoading(true)
		let data = {}
		data.id = id

		let url = new URL(urldata + 'admin/deleteBannerImage')

		axios({
			method: 'post',
			url: url,
			data: data,
			withCredentials: true,
		})
			.then((response) => {
				//   showToast("success");
				//   props.onHide(); props.history.push('/admin/login')
				console.log(response)

				const templist = response.data.data.reverse()
				console.log(templist)
				setList(templist)
				setLoading(false)
				showToast('delete')
			})
			.catch((error) => {
				// showToast("danger");
				setLoading(false)
			})
	}

	if (loading) {
		return (
			<div
				style={{
					width: '100%',
					height: '80vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
				id='wrapper'
			>
				<div style={{ width: '10em' }} className='profile-main-loader'>
					<div className='loader'>
						<img src={loader} />
					</div>
				</div>
			</div>
		)
	}
	return (
		<>
			<div className='addjobinlist text-right mb-4'>
				<Button title='Add Banner' onClick={handleShow} />
			</div>
			<MaterialTable
				title='Homepage Banner List'
				columns={[
					// { title: 'Text', field: 'text1' },

					// { title: 'Button Link', field: 'link' },

					{
						field: 'url',

						title: 'Mobile Image  ',
						render: (rowData) => (
							<div className='d-f'>
								<Button
									title='View'
									onClick={() => {
										window.open(Imageurldata + rowData.mbimgUrl, '_blank')
									}}
								></Button>
							</div>
						),
					},
					{
						field: 'url',

						title: 'Desktop Image  ',
						render: (rowData) => (
							<div className='d-f'>
								<Button
									title='View'
									onClick={() => {
										window.open(Imageurldata + rowData.imgUrl, '_blank')
									}}
								></Button>
							</div>
						),
					},
					{
						field: 'url',

						title: 'Action  ',
						render: (rowData) => (
							<div className='d-f'>
								<Button
									title=' Update'
									onClick={() => {
										handleShowForUpdate(rowData)
									}}
								></Button>
								&nbsp;&nbsp;
								<Button
									title=' Delete'
									onClick={() => deleteBtnClick(rowData._id)}
								></Button>
							</div>
						),
					},
				]}
				data={list}
				options={{
					exportButton: true,
					actionsColumnIndex: -1,
				}}
			/>

			<Modal
				{...props}
				//   size="lg"
				//   aria-labelledby="contained-modal-title-vcenter"
				dialogClassName='addbannerModel'
				className='bannerList'
				centered
				show={show}
				onHide={handleClose}
			>
				<Modal.Header closeButton>
					<h3>Add Banner</h3>
				</Modal.Header>
				<Modal.Body className='model'>
					<Row>
						{/* <Col lg={3} className='column'>
							<div className='titleitem'>Text </div>
						</Col>

						<Col lg={9} className='column'>
							<input
								value={textline1}
								onChange={(e) => settextline1(e.target.value)}
								// maxlength="31"
								type='text'
							/>

							// <div className="count">{textline1.length}/31</div> 
						</Col>
						<Col lg={12}>
							<br></br>
						</Col> */}
						{/* <Col lg={3} className='column'>
							<div className='titleitem'>Button Link</div>
						</Col> */}
						{/* <Col lg={9} className='column'>
							<input
								value={link}
								onChange={(e) => setlink(e.target.value)}
								maxlength='31'
								type='text'
							/>
						</Col>
						<Col lg={12}>
							<br></br>
						</Col> */}
						<Col lg={3} className='column'>
							<div className='titleitem'>Add Desktop Image</div>
							<div className='desc'>Size :- 2560 × 1306</div>
						</Col>
						<Col lg={9} className='column'>
							<input
								id='bannerImage'
								accept='image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG'
								type='file'
								hidden
								onChange={(e) => {
									setbannerimg(e.target.files[0])
									setShowDesktopImg(true)
									setbannerimgnotpresent(true)
								}}
								name='passport_proof_upload'
							/>

							<label for='bannerImage' className='d-f' id='file-drag'>
								<img src={upload} class='img-fluid' />
								<div class='chooseTxt'>Choose File</div>
							</label>

							<div className='d-f jc-sb'>
								<div className='fileTxt'>File Format : JPG / png</div>
								<div className='fileTxt'>File Size: Less than 300kb </div>
							</div>
							<div className='err'>{bannerimgerr} </div>

							<div>
								{bannerimg !== '' && (
									<img
										src={
											showDesktopImg
												? URL.createObjectURL(bannerimg)
												: bannerimg
										}
										className='img-fluid'
										style={{ width: '100px', height: '100px' }}
									/>
								)}
							</div>
						</Col>
						<Col lg={12}>
							<br></br>
						</Col>
						<Col lg={3} className='column'>
							<div className='titleitem'>Add Mobile Image</div>
							<div className='desc'>Size :- 640 × 630</div>
						</Col>
						<Col lg={9} className='column'>
							<input
								hidden
								id='bannerImage1'
								accept='image/jpg ,image/png, image/JPG ,image/PNG, image/jpeg, image/JPEG '
								type='file'
								onChange={(e) => {
									setmbbannerimg(e.target.files[0])
									setShowMobImg(true)
									setmbImgNotPresent(true)
								}}
								name='passport_proof_upload'
							/>

							<label for='bannerImage1' className='d-f' id='file-drag'>
								<img src={upload} class='img-fluid' />
								<div class='chooseTxt'>Choose File</div>
							</label>

							<div className='d-f jc-sb'>
								<div className='fileTxt'>File Format : JPG / png</div>
								<div className='fileTxt'>File Size: Less than 200kb </div>
							</div>
							<div className='err'>{mbbannerimgerr} </div>

							<div>
								{mbbannerimg !== '' && (
									<img
										src={
											showMobImg
												? URL.createObjectURL(mbbannerimg)
												: mbbannerimg
										}
										className='img-fluid'
										style={{ width: '100px', height: '100px' }}
									/>
								)}
							</div>
						</Col>
					</Row>
					<div className='btnContainer mt-4'>
						<Button onClick={submit} title='SUBMIT' />
					</div>
				</Modal.Body>
			</Modal>
			<Toast toastList={toastlist} position='top-right' />
		</>
	)
}

export default HomepageBannerList

// updateBannerImages
