import React from 'react'
import './style.less'

const BannerItem = (props) => {
	return (
		<div className='BannerItemContainer wow fadeIn'>
			<img src={props.img} className='fluid' />
			<div className='BannerContainer'>
				<div
					style={props.color ? { color: props.color } : { color: 'white' }}
					className='title'
				>
					{props.title}
				</div>
				<div className='bannerdesc'>{props.desc}</div>
			</div>
		</div>
	)
}

export default BannerItem
