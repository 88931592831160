import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import urldata from '../../../urldata'
import Button from '../../../component/Button/Button'
import { Col, Row, Modal } from 'react-bootstrap'
import InputText from '../../../component/InputText/InputText'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import upload from '../../../images/careers/upload.svg'
import Toast from '../../../component/Toast/Toast'
import close from '../../../images/close.svg'
import check from '../../../images/check.svg'
import './style.less'
import loader from '../../loader.svg'
const expinyearsOptions = [
	{
		name: '',
	},
	{
		name: 'Months',
	},
	{
		name: 'Years',
	},
]

const JobPostlist = (props) => {
	const [loading, setLoading] = useState(false)
	const [list, setList] = useState([])
	const [modalShow, setModalShow] = useState(false)
	const [show, setShow] = useState(false)
	const [exptabshow, setExptabshow] = useState(false)
	const [designation, setDesignation] = useState('')
	const [designationerr, setDesignationerr] = useState('')
	const [location, setLocation] = useState('')
	const [locationerr, setLocationErr] = useState('')
	const [desc, setDesc] = useState('')
	const [descerr, setDescErr] = useState('')
	const [skills, setSkills] = useState('')
	const [skillserr, setSkillsErr] = useState('')
	const [minexp, setMinexp] = useState('0')
	const [experr, setexpErr] = useState('')
	const [minexperr, setMinexpErr] = useState('')
	const [maxexp, setMaxexp] = useState('0')
	const [maxexperr, setMaxexpErr] = useState('')
	const [expinyears, setExpinyears] = useState('')
	const [expinyearserr, setExpinyearsErr] = useState('')

	const [updateModel, setUpdateModel] = useState(false)
	const [jobId, setjobId] = useState('')

	const [toastlist, setToastList] = useState([])

	let toastProperties = null

	const handleClose = () => setShow(false)
	const handleShow = () => {
		setUpdateModel(false)
		setExptabshow(false)
		setDesignation('')
		setLocation('')
		setDesc('')
		setSkills('')
		setMinexp('')
		setMaxexp('')
		setExpinyears('')
		setDescErr('')
		setDesignationerr('')
		setExpinyearsErr('')
		setLocationErr('')
		setMaxexpErr('')
		setMinexpErr('')
		setSkillsErr('')
		setexpErr('')
		setShow(true)
	}

	const handleShowForUpdate = (rowData) => {
		setjobId(rowData._id)
		setUpdateModel(true)
		if (rowData.experienceInYear === 'Years') {
			setExptabshow(true)
			setMinexp(rowData.fromExp)
			setMaxexp(rowData.toExp)
		} else {
			setExptabshow(false)
		}

		setDesignation(rowData.designation)
		setLocation(rowData.location)
		setDesc(rowData.desc)
		setSkills(rowData.skills)

		setExpinyears(rowData.experienceInYear)
		setDescErr('')
		setDesignationerr('')
		setExpinyearsErr('')
		setLocationErr('')
		setMaxexpErr('')
		setMinexpErr('')
		setSkillsErr('')
		setexpErr('')
		setShow(true)
	}

	useEffect(() => {
		setLoading(true)

		let url = new URL(urldata + 'admin/jobPost')
		axios({
			method: 'get',
			url: url,
			withCredentials: true,
		})
			.then((response) => {
				const templist = response.data.data.reverse()
				setList(templist)
				setLoading(false)
			})
			.catch((error) => {
				// showToast("danger");
				setLoading(false)
			})
	}, [])

	const submit = () => {
		var formIsValid = true

		if (!designation) {
			formIsValid = false
			setDesignationerr('*Please enter designation.')
		} else {
			setDesignationerr('')
		}

		if (!desc) {
			formIsValid = false
			setDescErr('*Please enter Desc.')
		} else {
			setDescErr('')
		}

		if (formIsValid) {
			if (updateModel) {
				updateJobPost()
			} else {
				createJobPost()
			}
		}
	}

	const createJobPost = () => {
		let data = {}
		data.designation = designation
		data.location = location
		data.desc = desc
		data.skills = skills
		console.log('bjbj')

		let url = new URL(urldata + 'admin/jobPost')

		axios({
			method: 'post',
			url: url,
			data: data,
			withCredentials: true,
		})
			.then((response) => {
				setDesignation('')
				setLocation('')
				setDesc('')
				setSkills('')
				setMinexp('')
				setMaxexp('')
				setExpinyears('')
				setexpErr('')
				showToast('success')
				const templist = response.data.data.reverse()
				setList(templist)
				setShow(false)
			})
			.catch((error) => {
				console.log(error)
				// showToast("danger");
			})
	}

	const updateJobPost = () => {
		let data = {}
		data.designation = designation
		data.location = location
		data.desc = desc
		data.skills = skills
		if (exptabshow) {
			data.fromExp = minexp
			data.toExp = maxexp
			data.experienceInYear = 'Years'
		} else {
			data.fromExp = 0
			data.toExp = 0
			data.experienceInYear = 'Fresher'
		}
		data.id = jobId

		let url = new URL(urldata + 'admin/updateJobPost')

		axios({
			method: 'post',
			url: url,
			data: data,
			withCredentials: true,
		})
			.then((response) => {
				setDesignation('')
				setLocation('')
				setDesc('')
				setSkills('')
				setMinexp('')
				setMaxexp('')
				setExpinyears('')
				setexpErr('')
				showToast('success')
				const templist = response.data.data.reverse()
				setList(templist)
				setShow(false)
			})
			.catch((error) => {
				// showToast("danger");
			})
	}

	const deleteBtnClick = (id) => {
		// alert(id);
		setLoading(true)
		let data = {}
		data.userId = id

		let url = new URL(urldata + 'admin/deleteJobPost')

		axios({
			method: 'post',
			url: url,
			data: data,
			withCredentials: true,
		})
			.then((response) => {
				//   showToast("success");
				//   props.onHide(); props.history.push('/admin/login')
				const templist = response.data.data.reverse()
				setList(templist)
				setLoading(false)
				showToast('delete')
			})
			.catch((error) => {
				// showToast("danger");

				setLoading(false)
			})
	}

	const showToast = (type) => {
		const id = Math.floor(Math.random() * 101 + 1)
		switch (type) {
			case 'success':
				toastProperties = {
					id,
					title: 'Success',
					description: 'Job Post Saved Successfully',
					backgroundColor: '#329F33',
					icon: check,
				}
				break
			case 'danger':
				toastProperties = {
					id,
					title: 'Something Went Wrong',
					description: '',
					backgroundColor: 'red',
					icon: close,
				}
				break
			case 'delete':
				toastProperties = {
					id,
					title: 'Success',
					description: 'Job post deleted Successfully',
					backgroundColor: '#329F33',
					icon: check,
				}
				break

			default:
				setToastList([])
		}

		setToastList([...toastlist, toastProperties])
	}

	if (loading) {
		return (
			<div
				style={{
					width: '100%',
					height: '80vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
				id='wrapper'
			>
				<div style={{ width: '10em' }} className='profile-main-loader'>
					<div className='loader'>
						<img src={loader} />
					</div>
				</div>
			</div>
		)
	}
	return (
		<>
			<div className='addjobinlist text-right mb-4'>
				<Button small title='Add Job Opening' onClick={handleShow} />
			</div>
			<MaterialTable
				title='Job Post List'
				columns={[
					{
						title: 'Designation',
						field: 'designation',
						width: '30%',
					},

					{
						title: 'Desc',
						//field: 'desc',

						render: (rowData) => (
							<div
								style={{
									maxHeight: '5em',
									overflow: 'auto',
									textOverflow: 'ellipsis',
								}}
								className='d-f'
							>
								{rowData.desc}
							</div>
						),
						width: '40%',
					},

					{
						field: 'url',
						width: '30%',
						title: 'Action  ',
						render: (rowData) => (
							<div className='d-f'>
								<Button
									title=' Update'
									onClick={() => {
										handleShowForUpdate(rowData)
									}}
								></Button>

								<Button
									title=' Delete'
									onClick={() => {
										deleteBtnClick(rowData._id)
									}}
								></Button>
							</div>
						),
					},
				]}
				data={list}
				options={{
					exportButton: true,
					actionsColumnIndex: -1,
				}}
			/>

			<Modal
				{...props}
				//   size="lg"
				//   aria-labelledby="contained-modal-title-vcenter"
				className='addJobModel'
				centered
				show={show}
				onHide={handleClose}
			>
				<Modal.Header closeButton>
					<h3>{updateModel ? 'Update Job Post' : 'Add a Job Opening'}</h3>
				</Modal.Header>
				<Modal.Body className='model'>
					<Row>
						<Col lg={12}>
							<InputText
								value={designation}
								onChange={(e) => setDesignation(e.target.value)}
								title='Designation*'
							/>
							<div className='err'>{designationerr}</div>
						</Col>

						<Col lg={12}>
							<InputText title='Descriptions*' disabled />
							<div style={{ height: '200px', paddingBottom: '20px' }}>
								<ReactQuill
									style={{ height: '100%' }}
									theme='snow'
									value={desc}
									onChange={setDesc}
								/>
							</div>
							<br></br>
							<br></br>
							<div className='err'>{descerr}</div>
						</Col>
					</Row>
					<br></br>
					<div className='btnContainer  mt-4'>
						<Button onClick={submit} title='SUBMIT' />
					</div>
				</Modal.Body>
			</Modal>
			<Toast toastList={toastlist} position='top-right' />
		</>
	)
}

export default JobPostlist
