import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import urldata from '../../../urldata'
import Button from '../../../component/Button/Button'
import { Col, Row, Modal } from 'react-bootstrap'
import InputText from '../../../component/InputText/InputText'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import upload from '../../../images/careers/upload.svg'
import Toast from '../../../component/Toast/Toast'
import close from '../../../images/close.svg'
import check from '../../../images/check.svg'
import loader from '../../loader.svg'
import moment from 'moment/moment'

import './style.less'
const expinyearsOptions = [
	{
		name: '',
	},
	{
		name: 'Months',
	},
	{
		name: 'Years',
	},
]

const FeedbackCenterList = (props) => {
	const [loading, setLoading] = useState(false)
	const [list, setList] = useState([])
	const [modalShow, setModalShow] = useState(false)
	const [show, setShow] = useState(false)
	const [exptabshow, setExptabshow] = useState(false)
	const [designation, setDesignation] = useState('')
	const [designationerr, setDesignationerr] = useState('')
	const [location, setLocation] = useState('')
	const [locationerr, setLocationErr] = useState('')
	const [desc, setDesc] = useState('')
	const [descerr, setDescErr] = useState('')
	const [skills, setSkills] = useState('')
	const [skillserr, setSkillsErr] = useState('')
	const [minexp, setMinexp] = useState('0')
	const [experr, setexpErr] = useState('')
	const [minexperr, setMinexpErr] = useState('')
	const [maxexp, setMaxexp] = useState('0')
	const [maxexperr, setMaxexpErr] = useState('')
	const [expinyears, setExpinyears] = useState('')
	const [expinyearserr, setExpinyearsErr] = useState('')

	const [updateModel, setUpdateModel] = useState(false)
	const [jobId, setjobId] = useState('')

	const [toastlist, setToastList] = useState([])

	let toastProperties = null

	const handleClose = () => setShow(false)
	const handleShow = () => {
		setUpdateModel(false)
		setExptabshow(false)
		setDesignation('')
		setLocation('')
		setDesc('')
		setSkills('')
		setMinexp('')
		setMaxexp('')
		setExpinyears('')
		setDescErr('')
		setDesignationerr('')
		setExpinyearsErr('')
		setLocationErr('')
		setMaxexpErr('')
		setMinexpErr('')
		setSkillsErr('')
		setexpErr('')
		setShow(true)
	}

	const getList = async () => {
		setLoading(true)
		let url = new URL(urldata + 'admin/getFeedBackList')
		axios({
			method: 'get',
			url: url,
			withCredentials: true,
		})
			.then((response) => {
				console.log(response.data.data.list)
				const templist = response.data.data.list.map((item) => {
					return {
						...item,
						createdAt: new moment(item.createdAt).format('DD/MM/YYYY'),
					}
				})
				setList(templist)
				console.log({ templist })
				setLoading(false)
			})
			.catch((error) => {
				// showToast("danger");
				setLoading(false)
			})
	}

	useEffect(() => {
		getList()
	}, [])

	if (loading) {
		return (
			<div
				style={{
					width: '100%',
					height: '80vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
				id='wrapper'
			>
				<div style={{ width: '10em' }} className='profile-main-loader'>
					<div className='loader'>
						<img src={loader} />
					</div>
				</div>
			</div>
		)
	}
	return (
		<>
			<MaterialTable
				title='Feedback List'
				columns={[
					{
						title: 'Time',
						field: 'createdAt',
						width: '10%',
					},
					{
						title: 'Name',
						field: 'name',
						width: '20%',
					},

					{
						title: 'Phone Number',
						field: 'phoneNumber',
						width: '20%',
					},

					{
						title: 'Tracking Number',
						field: 'trackingNumber',
						width: '30%',
					},

					{
						title: 'Message',
						field: 'message',
						width: '40%',
					},

					{
						title: 'Complaint Type',
						field: 'complaintType',
						width: '30%',
					},

					{
						title: 'Complainer ',
						field: 'complainer',
						width: '30%',
					},
				]}
				data={list}
				options={{
					exportButton: true,
					actionsColumnIndex: -1,
				}}
			/>

			<Toast toastList={toastlist} position='top-right' />
		</>
	)
}

export default FeedbackCenterList
