import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BannerItem from '../../component/BannerItem/BannerItem'
import useWindowDimensions from '../../useWindowDimensions'
import introduction from '../../images/aboutus/introduction.png'
import company from '../../images/aboutus/company.png'
import WOW from 'wowjs'
import banner from '../../images/aboutus/banner.png'
import Intro from '../../images/aboutus/Intro.png'
import welcome from '../../images/aboutus/03- Welcome.png'
import pickup from '../../images/aboutus/04 - Pickup B.png'
import real from '../../images/aboutus/05 - Real Time B.png'
import claim from '../../images/aboutus/06 - Claim B.png'
import consultation from '../../images/aboutus/07 - Consultation B.png'
import shipping from '../../images/aboutus/08 - Shipping B.png'
import expert from '../../images/aboutus/09 - Expert B.png'
import InputText from '../../component/InputText/InputText'
import Button from '../../component/Button/Button'
import 'swiper/swiper-bundle.css'
import Swiper, {
	Navigation,
	Pagination,
	Autoplay,
	Thumbs,
	EffectFade,
} from 'swiper'
import './style.less'
import { Col, Row } from 'antd'
import Footer from '../../component/Footer1/Footer'

import mobile834 from '../../images/aboutus/mobile834.png'

import { useEffect } from 'react'
import axios from 'axios'
import urldata from '../../urldata'
Swiper.use([Navigation, Pagination, Autoplay])
const AboutUs = (props) => {
	const { t } = useTranslation()
	const { height, width } = useWindowDimensions()
	const [name, setName] = useState('')
	const [nameerr, setNameerr] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [phoneNumbererr, setPhoneNumbererr] = useState('')
	const [trackingNumber, setTrackingNumber] = useState('')
	const [emailId, setEmailId] = useState('')
	const [emailIderr, setEmailIderr] = useState('')
	const [message, setMessage] = useState('')
	const [messageerr, setMessageErr] = useState('')
	const [findType, setFindType] = useState('')

	useEffect(() => {
		window.scroll(0, 0)
		document.title = 'Wyngs - About Us'
		new WOW.WOW({
			live: false,
		}).init()
		const swiper2 = new Swiper('.about_sec3-swiper', {
			slidesPerView: 1,
			spaceBetween: 10,

			pagination: {
				el: '.about_sec3 .swiper-pagination',
				clickable: true,
			},
		})
	}, [])

	const handleOnSubmit = async () => {
		var formIsValid = true

		if (!name) {
			formIsValid = false
			setNameerr('*Please enter your name.')
		} else {
			setNameerr('')
		}

		if (!phoneNumber) {
			formIsValid = false
			setPhoneNumbererr('*Please enter your phone Number.')
		} else {
			setPhoneNumbererr('')
		}
		if (formIsValid) {
			try {
				const data = await axios.post(urldata + 'user/addGetInToch', {
					name,
					phoneNumber,
					howDidYouFindUs: findType,
					email: emailId,
				})
				setName('')
				setPhoneNumber('')
				setFindType('')
				setEmailId('')

				document.getElementById('findType').value = ''
			} catch (error) {
				console.log(error)
				alert(error?.response?.data?.msg || 'Something went wrong')
			}
		}
	}

	return (
		<>
			<BannerItem
				img={width > 835 ? banner : mobile834}
				title={t('about.title')}
				color='white'
			/>

			<section className='about_sec1 wow fadeIn d-f j-c a-c '>
				<div className='container'>
					<Row>
						<Col lg={12} className='d-f'>
							<div className='contentWrapper'>
								<div className='content'>
									<span className='textWrapper'>
										<span>
											{' '}
											<img
												className='fluid'
												src={introduction}
												alt='introduction'
											/>
											&nbsp;&nbsp;{t('about.desc')}
										</span>
									</span>
									<div className='title'>{t('about.title1')}</div>
									<p>
										<strong>{t('about.title2')}</strong> {t('about.desc2')}
									</p>
								</div>
							</div>
						</Col>
						<Col lg={12} md={24} className=''>
							<div className='imgcontainer'>
								<img src={Intro} className='fluid' />
							</div>
						</Col>
					</Row>
				</div>
			</section>

			<section className='about_sec2 wow fadeIn d-f j-c a-c '>
				<div className='container'>
					<Row>
						<Col lg={12} md={12} className='d-f h-100 f-c'>
							<div className='contentWrapper'>
								<div className='content '>
									<span className='textWrapper'>
										<span>
											<img className='fluid' src={company} alt='company' />
											&nbsp;&nbsp;{t('about.desc1')}
										</span>
									</span>
									<div className='title'>{t('about.title3')}</div>
									<p>{t('about.desc3')}</p>
									<div className='imgcontainer'>
										<img src={welcome} className='fluid' />
									</div>
								</div>
							</div>
						</Col>
						<Col lg={12} md={12} className=' d-f j-c a-c'>
							<div className='rightcontainer d-f a-c j-c f-c'>
								<div className='text1'>
									<div class='box arrow-left'>
										<div className='img-area'>{t('about.title4')}</div>
										<p>{t('about.desc4')}</p>
									</div>
								</div>
								<div className='text1'>
									<div class='box arrow-left'>
										<div className='img-area j-c a-c '>{t('about.title5')}</div>
										<p>{t('about.desc5')}</p>
									</div>
								</div>
								<div className='text1'>
									<div class='box arrow-left'>
										<div className='img-area'>{t('about.title6')}</div>
										<p>{t('about.desc6')}</p>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</section>

			<section className='about_sec3 wow fadeIn d-f j-c f-c a-c'>
				<span className='sub-desc d-f j-c a-c'> {t('about.subTitle')}</span>
				<div className='title'>{t('about.title7')}</div>
				<div className='desc'>{t('about.desc7')}</div>
				{width >= 1024 ? (
					<div className='gridcontainer d-f a-c j-c'>
						<div className='imgcontainer'>
							<img className='strength fluid' src={pickup} />
							<div className='text'>{t('about.grid1')}</div>
						</div>
						<div className='imgcontainer'>
							<img className='strength fluid' src={real} />
							<div className='text'>{t('about.grid2')}</div>
						</div>
						<div className='imgcontainer'>
							<img className='strength fluid' src={claim} />
							<div className='text'>{t('about.grid3')}</div>
						</div>
						<div className='imgcontainer'>
							<img className='strength fluid' src={consultation} />
							<div className='text'>{t('about.grid4')}</div>
						</div>
						<div className='imgcontainer'>
							<img className='strength fluid' src={shipping} />
							<div className='text'>{t('about.grid5')}</div>
						</div>
						<div className='imgcontainer'>
							<img className='strength fluid' src={expert} />

							<div className='text'>{t('about.grid6')}</div>
						</div>
					</div>
				) : (
					<div className='p-r  swiper3About'>
						<div className='about_sec3-swiper swiper-container'>
							<div className='swiper-wrapper'>
								<div className='swiper-slide'>
									<div className='imgcontainer  p-r'>
										<img className='fluid' src={pickup} />
									</div>
									<div className='text'>{t('about.grid1')}</div>
								</div>
								<div className='swiper-slide'>
									<div className='imgcontainer  p-r'>
										<img className='fluid' src={real} />
									</div>
									<div className='text'>{t('about.grid2')}</div>
								</div>
								<div className='swiper-slide'>
									<div className='imgcontainer  p-r'>
										<img className='fluid' src={claim} />
									</div>
									<div className='text'>{t('about.grid3')}</div>
								</div>
								<div className='swiper-slide'>
									<div className='imgcontainer  p-r'>
										<img className='fluid' src={consultation} />
									</div>
									<div className='text'>{t('about.grid4')}</div>
								</div>
								<div className='swiper-slide'>
									<div className='imgcontainer  p-r'>
										<img className='fluid' src={shipping} />
									</div>
									<div className='text'>{t('about.grid5')}</div>
								</div>
								<div className='swiper-slide'>
									<div className='imgcontainer  p-r'>
										<img className='fluid' src={expert} />
									</div>
									<div className='text'>{t('about.grid6')}</div>
								</div>
							</div>
						</div>
						<div className='swiper-pagination'></div>
					</div>
				)}
			</section>

			<section className='about_sec4 wow fadeIn d-f j-c f-c a-c'>
				<div className='aboutContainer'>
					<Row>
						<Col lg={12} sm={24}>
							<div className='container'>
								<div className='title'> {t('about.title8')}</div>
								<div className='desc'> {t('about.desc8')}</div>
								<div className='card'>
									<div className='formContainer w-f'>
										<div className='mt-4'>
											<InputText
												value={name}
												onChange={(e) => setName(e.target.value)}
												title='Name*'
											/>
											<div className='error'>{nameerr}</div>
										</div>
										<div className='mt-4'>
											<InputText
												value={emailId}
												onChange={(e) => setEmailId(e.target.value)}
												title='Email'
											/>
											<div className='error'>{emailIderr}</div>
										</div>
										<div className='mt-4'>
											<InputText
												value={phoneNumber}
												onChange={(e) => setPhoneNumber(e.target.value)}
												title='Phone Number*'
											/>
											<div className='error'>{phoneNumbererr}</div>
										</div>

										<div className='mt-4'>
											<InputText
												dropdown
												list={[
													{
														name: '',
														value: '',
														notselected: true,
													},
													{
														name: 'Newspaper',
														value: 'Newspaper',
													},
													{
														name: 'Friend',
														value: 'Friend',
													},
													{
														name: 'Internet',
														value: 'Internet',
													},
												]}
												onChange={(e) => setFindType(e.target.value)}
												value={findType}
												title='How did you find us?'
												id='findType'
											/>
										</div>

										<div className='submitbtnContainer'>
											<Button
												onClick={handleOnSubmit}
												ogFull={true}
												title='SEND'
											/>
										</div>
									</div>
								</div>
							</div>
						</Col>
						<Col lg={12} sm={24}>
							<div className='mapContainer'>
								<iframe
									src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.246923653136!2d101.5443305749465!3d3.0282634969476177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdb2b62fc6481d%3A0xc93fdc9e290700fe!2s32%2C%20Jalan%20Sepadu%2025%2F123A%2C%20Taman%20Sri%20Muda%2C%2040400%20Shah%20Alam%2C%20Selangor%2C%20Malaysia!5e0!3m2!1sen!2sin!4v1690690712678!5m2!1sen!2sin'
									width='100%'
									height='100%'
									style={{ border: '0' }}
									allowfullscreen=''
									loading='lazy'
									referrerpolicy='no-referrer-when-downgrade'
								></iframe>
							</div>
						</Col>
					</Row>
				</div>
			</section>
		</>
	)
}

export default AboutUs
