import React, { Component } from 'react'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom'
import { createBrowserHistory } from 'history'

import Homepage from './container/Home Page/Homepage'

import Header from './component/Header/Header'

import Program from './container/Program/Program'

import Support from './container/Support/Support'
import AboutUs from './container/About Us/AboutUs'
import Business_Benefit from './container/Business Benefits/Business_Benefit'
import Transit from './container/Transit KPI/Transit'
import Destination2 from './container/Destination2/Destination2'
import DisputeCenter from './container/DisputeCenter/DisputeCenter'
import Careers from './container/Careers/Careers'
import Footer1 from './component/Footer1/Footer'
import Service from './container/Service/Service'
import EcommercePlatform from './container/EcommercePlatform/EcommercePlatform'
import TrackingDetails from './container/TrackingDetails/TrackingDetails'
import Faq from './container/Faq/Faq'
import HowToUse from './container/HowToUse/HowToUse'

const history = createBrowserHistory()

class ScrollToTopButton extends Component {
	// Function to scroll to the top of the page
	scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth', // Optional smooth scrolling animation
		})
	}

	render() {
		return (
			<button className='scroll-to-top-button' onClick={this.scrollToTop}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					stroke='currentColor'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				>
					<line x1='12' y1='19' x2='12' y2='5' />
					<polyline points='5 12 12 5 19 12' />
				</svg>
			</button>
		)
	}
}

const DefaultLayout = (props) => {
	return (
		<>
			<Header new={true} />
			<div className='marginMiddleHomeContainer'>
				<Switch>
					<Route exact path='/' component={Homepage} />
					<Route exact path='/program' component={Program} />
					<Route exact path='/dispute-center' component={DisputeCenter} />

					<Route
						exact
						path='/ecommercePlatform'
						component={EcommercePlatform}
					/>

					<Route exact path='/support' component={Support} />
					<Route exact path='/about' component={AboutUs} />
					<Route exact path='/business' component={Business_Benefit} />
					<Route exact path='/transit' component={Transit} />
					<Route exact path='/destination' component={Destination2} />
					<Route exact path='/homepage' component={Homepage} />
					<Route exact path='/careers' component={Careers} />
					<Route exact path='/service' component={Service} />
					<Route exact path='/trackingDetails' component={TrackingDetails} />
					<Route exact path='/faq' component={Faq} />
					<Route exact path='/how-to-use' component={HowToUse} />
					<Redirect to='/404' />
				</Switch>
			</div>

			<ScrollToTopButton />
			<Footer1 />
		</>
	)
}

export default DefaultLayout
