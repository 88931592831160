import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BannerItem from '../../component/BannerItem/BannerItem'
import useWindowDimensions from '../../useWindowDimensions'
import './style.less'
import { Col, model, Row } from 'antd'
import 'swiper/swiper-bundle.css'
import WOW from 'wowjs'
import { useEffect } from 'react'
import InputText from '../../component/InputText/InputText'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import create from '../../images/destination2/create.png'

import Swiper, {
	Navigation,
	Pagination,
	Autoplay,
	Thumbs,
	EffectFade,
} from 'swiper'
import Button from '../../component/Button/Button'
import RatingContainer from '../../component/RatingContainer/RatingContainer'

const HowToUse = () => {
	const { t } = useTranslation()
	const { height, width } = useWindowDimensions()

	useEffect(() => {
		window.scroll(0, 0)
		document.title = 'Wyngs - How To Use'
		new WOW.WOW({
			live: false,
		}).init()
	}, [])

	return (
		<div>
			<section className='how_sec wow fadeIn'>
				<div className='titleContainer'>
					<img className='fluid' src={create} />
					&nbsp;{t('How.title')}{' '}
				</div>
				<div className='container'>
					<div className='question'>{t('How.question1')}</div>
					<div className='answer'>
						{t('How.answer1')}
						{/* <ol type='1' style={{listStyle:'bold'}}> */}
						<li>
							<strong>{t('How.l1')} </strong>
							{t('How.desc1')}
						</li>
						<li>
							<strong>{t('How.l2')} </strong>
							{t('How.desc2')}
						</li>
						<li>
							<strong>{t('How.l3')}</strong> {t('How.desc3')}{' '}
						</li>
						{/* </ol> */}
					</div>

					<div className='question'>{t('How.question2')}</div>
					<div className='answer'>{t('How.answer2')}</div>

					<div className='question'>{t('How.question3')}</div>
					<div className='answer'>{t('How.answer3')}</div>
				</div>
			</section>
		</div>
	)
}

export default HowToUse
