import React from 'react'
import './style.less'
import emptyUser from '../../images/program/emptyUser.svg'
const StepComponent = (props) => {
	return (
		<div className='d-f stepComponent '>
			<div className='stepContainer'>
				<div>
					<div className='stepTitle'>{props.Count}</div>
					<div className='t-c'>
						<img className='logo fluid' src={props.icon ?? emptyUser} />
					</div>
				</div>
			</div>

			<div className='stepDescContainer'>
				<div className='stepTitle'>{props.title}</div>
				<div className='stepDesc'>{props.desc}</div>
			</div>
		</div>
	)
}

export default StepComponent
