import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import urldata, { Imageurldata } from '../../../urldata'
import Button from '../../../component/Button/Button'
import moment from 'moment'
import loader from '../../loader.svg'

const JobApplication = (props) => {
	const [loading, setLoading] = useState(false)
	const [list, setList] = useState([])

	useEffect(() => {
		setLoading(true)

		let url = new URL(urldata + 'admin/jobApplication')
		axios({
			method: 'get',
			url: url,
			withCredentials: true,
		})
			.then((response) => {
				const list = response.data.data.reverse()
				setList(list)

				setLoading(false)
			})
			.catch((error) => {
				// alert("Something Went Wrong");
			})
	}, [])

	if (loading) {
		return (
			<div
				style={{
					width: '100%',
					height: '80vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
				id='wrapper'
			>
				<div style={{ width: '10em' }} className='profile-main-loader'>
					<div className='loader'>
						<img src={loader} />
					</div>
				</div>
			</div>
		)
	}
	return (
		<>
			<MaterialTable
				title='Job Applications'
				columns={[
					{
						title: 'Date',
						render: (rowData) => (
							<div>
								{new moment(rowData.createdAt).format('DD/MM/YYYY hh.mm a')}
							</div>
						),
					},

					{
						title: 'Name',
						field: 'name',
						cellStyle: {
							width: 20,
							maxWidth: 20,
						},
						headerStyle: {
							width: 20,
							maxWidth: 20,
						},
					},
					{
						title: 'Email Id',
						field: 'emailId',
					},
					{
						title: 'Phone Number',
						field: 'phoneNumber',
					},
					{ title: 'Post Apply For', field: 'postApplyFor' },
					{ title: 'Summary/message', field: 'messageOrSummary' },

					{
						field: 'url',
						title: 'Resume Link',
						render: (rowData) => (
							<Button
								title=' View Resume'
								small={true}
								onClick={() => {
									window.open(
										Imageurldata + rowData.resumeLink,

										'_blank' // <- This is what makes it open in a new window.
									)
								}}
							></Button>
						),
					},
				]}
				data={list}
				options={{
					exportButton: true,
					actionsColumnIndex: -1,
				}}
			/>
		</>
	)
}

export default JobApplication
