import React from 'react'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom'
import { createBrowserHistory } from 'history'

import './App.css'
import 'react-quill/dist/quill.snow.css'
// import Login from './container/Login/Login'
import DefaultLayout from './DefaultLayout'
// import Dashboard from './container/Dashboard/Dashboard'
import PageNotFound from './container/PageNotFound/PageNotFound'

import Login from '../src/Admin/container/Login/Login'
import Dashboard from '../src/Admin/container/Dashboard/Dashboard'
import LoginD from '../src/container/Login/Login'

const history = createBrowserHistory()

const PrivateRoute = ({ component: Component, ...rest }) => {
	const isAuth = localStorage.getItem('isLoggedIn')
	return (
		// Show the component only when the user is logged in
		// Otherwise, redirect the user to /signin page
		<Route
			{...rest}
			render={(props) =>
				isAuth ? <Component {...props} /> : <Redirect to='/admin' />
			}
		/>
	)
}
const App = (props) => {
	return (
		<>
			<Router history={history}>
				<Switch>
					<Route path='/login' component={LoginD} />
					<Route exact path='/admin' component={Login} />
					<PrivateRoute path='/admin/dashboard/' component={Dashboard} />
					<Route path='/' component={DefaultLayout} />
				</Switch>
			</Router>
		</>
	)
}

export default App
