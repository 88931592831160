import React, { useState, useEffect } from 'react'
import axios from 'axios'

const mapApiJs = 'https://maps.googleapis.com/maps/api/js'

function AddressAutocomplete(props) {
	const [predictions, setPredictions] = useState([])
	const handleInputChange = (e) => {
		const inputAddress = e.target.value
		// setAddress(inputAddress)
		props.onChange(inputAddress)
		if (inputAddress.length > 3) {
			getPlacePredictions(inputAddress)
		} else {
			setPredictions([])
		}
	}

	const getPlacePredictions = async (input) => {
		try {
			const response = await axios.get(
				`https://maps.googleapis.com/maps/api/place/js/PlaceService.GetPlaceDetails?input=${input}&key=AIzaSyCHXfEvF8wJ9Ig2ztnAPHPactI_-ap29sc`
			)

			setPredictions(response.data.predictions)
		} catch (error) {
			console.error('Error fetching predictions:', error)
		}
	}

	const handlePredictionClick = (prediction) => {
		// console.log()

		// let country = ''
		// let state = ''
		// let city = ''

		// // Loop through the terms array to extract relevant information
		// for (const term of prediction.terms) {
		// 	console.log('in', term)
		// 	if (term.offset === 27) {
		// 		// "Maharashtra" is at offset 27, indicating the state
		// 		state = term.value
		// 	} else if (term.offset === 40) {
		// 		// "India" is at offset 40, indicating the country
		// 		country = term.value
		// 	} else {
		// 		// All other terms are considered as part of the city
		// 		city += term.value + ' '
		// 	}
		// }
		// console.log({ country, state, city })
		props.onChange(prediction)
		setPredictions([])
	}

	return (
		<div className='w-f'>
			<input
				type='text'
				placeholder='Enter an address'
				value={props?.value}
				onChange={handleInputChange}
			/>
			<ul>
				{predictions.map((prediction) => (
					<li
						key={prediction.place_id}
						onClick={() => handlePredictionClick(prediction)}
					>
						{prediction.description}
					</li>
				))}
			</ul>
		</div>
	)
}

export default AddressAutocomplete
