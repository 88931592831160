import React from 'react'
import { Carousel, Row, Col } from 'antd'
import banner from '../../images/destination2/banner.png'
import australia from '../../images/destination2/australia-01.png'
import europe from '../../images/destination2/europe-01.png'
import fedex from '../../images/destination2/fedex.png'
import sabah from '../../images/destination2/sabah & sarawak.png'
import united from '../../images/destination2/united kingdom-01.png'
import singapore from '../../images/destination2/singapore-01.png'
import { useTranslation } from 'react-i18next'
import BannerItem from '../../component/BannerItem/BannerItem'
import useWindowDimensions from '../../useWindowDimensions'
import create from '../../images/destination2/create.png'
import mobile834 from '../../images/destination2/mobile834.png'
import './style.less'
import WOW from 'wowjs'

import 'swiper/swiper-bundle.css'

import { useEffect } from 'react'
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
import FooterContactUsItem from '../../component/FooterContactUsItem/FooterContactUsItem'

Swiper.use([Navigation, Pagination, Autoplay])
function Destination2() {
	const { t } = useTranslation()
	const { height, width } = useWindowDimensions()

	useEffect(() => {
		window.scroll(0, 0)
		document.title = 'Wyngs - Destination'
		new WOW.WOW({
			live: false,
		}).init()

		const swiper2 = new Swiper('.destination_sec1-swiper', {
			slidesPerView: 1,
			spaceBetween: 10,
			pagination: {
				el: '.destination_sec1 .swiper-pagination',
				clickable: true,
			},
		})
	}, [])

	// const smallSliderList = bannerArr.map((item, i) => (
	// 	<div key={i} className='swiper-slide'>
	// 		{/* <img className='fluid' src={banner} /> */}
	// 		<>
	// 		<div class='card'>
	// 							<div className='img-area d-f'>
	// 								<img
	// 									style={{ marginTop: 'auto', marginBottom: 'auto' }}
	// 									className='dest fluid '
	// 									src={fedex}
	// 								/>
	// 							</div>
	// 							<div class='card-body'>
	// 								<h5 class='card-title'>Fedex Priority</h5>
	// 							</div>
	// 		</div>

	// 		</>
	// 	</div>
	// ))

	return (
		<>
			<BannerItem
				img={width > 835 ? banner : mobile834}
				title={t('destination.title')}
				color='white'
			/>

			<section className='destination_sec1 wow fadeIn d-f j-c a-c f-c'>
				<div className='container'>
					<div className='titleContainer'>
						<img className='fluid' src={create} />
						&nbsp;{t('destination.title')}{' '}
					</div>
					<div className='title'>{t('destination.title1')}</div>
					<div className='desc'></div>

					{width >= 1024 ? (
						<>
							<div className='flex-container d-f a-c j-c f-c'>
								<div class='card'>
									<div className='img-area d-f'>
										<img
											style={{ marginTop: 'auto', marginBottom: 'auto' }}
											className='dest fluid '
											src={fedex}
										/>
									</div>
									<div class='card-body'>
										<h5 class='card-title'>{t('destination.title2')}</h5>
									</div>
								</div>

								<div class='card'>
									<div className='img-area'>
										<img
											className='dest fluid card-img-top'
											style={{ marginTop: '-4.375em', marginBottom: 'auto' }}
											src={australia}
										/>
									</div>
									<div class='card-body'>
										<h5 class='card-title'>{t('destination.title3')}</h5>
									</div>
								</div>

								<div class='card'>
									<div className='img-area'>
										<img className='dest fluid card-img-top' src={europe} />
									</div>
									<div class='card-body'>
										<h5 class='card-title'>{t('destination.title4')}</h5>
									</div>
								</div>

								<div class='card'>
									<div className='img-area'>
										<img
											style={{ marginTop: '-9.375em' }}
											className='dest fluid  card-img-top'
											src={united}
										/>
									</div>
									<div class='card-body'>
										<h5 class='card-title'>{t('destination.title5')}</h5>
									</div>
								</div>

								<div class='card'>
									<div className='img-area'>
										<img
											style={{ marginTop: '-5.000em' }}
											className='dest fluid card-img-top'
											src={sabah}
										/>
									</div>
									<div class='card-body'>
										<h5 class='card-title'>{t('destination.title6')}</h5>
									</div>
								</div>

								<div class='card'>
									<div className='img-area'>
										<img
											style={{ marginTop: '-6.875em' }}
											className='dest fluid card-img-top '
											src={singapore}
										/>
									</div>
									<div class='card-body'>
										<h5 class='card-title'>{t('destination.title7')}</h5>
									</div>
								</div>
							</div>
						</>
					) : (
						<div className='p-r flex-container d-f j-c a-c f-c'>
							<div className='  swiper3Destination d-f j-c a-c'>
								<div className='destination_sec1-swiper swiper-container'>
									<div className='swiper-wrapper'>
										<div className='swiper-slide'>
											<div class='card'>
												<div className='img-area d-f'>
													<img
														style={{ marginTop: 'auto', marginBottom: 'auto' }}
														className='dest fluid '
														src={fedex}
													/>
												</div>
												<div class='card-body'>
													<h5 class='card-title'>{t('destination.title2')}</h5>
												</div>
											</div>
										</div>
										<div className='swiper-slide'>
											<div class='card'>
												<div className='img-area'>
													<img
														className='dest fluid '
														style={{ marginTop: '-20px', marginBottom: 'auto' }}
														src={australia}
													/>
												</div>
												<div class='card-body'>
													<h5 class='card-title'>{t('destination.title3')}</h5>
												</div>
											</div>
										</div>
										<div className='swiper-slide'>
											<div class='card'>
												<div className='img-area'>
													<img
														className='dest fluid '
														src={europe}
														style={{ marginTop: 'auto', marginBottom: 'auto' }}
													/>
												</div>
												<div class='card-body'>
													<h5 class='card-title'>{t('destination.title4')}</h5>
												</div>
											</div>
										</div>
										<div className='swiper-slide'>
											<div class='card'>
												<div className='img-area'>
													<img
														style={{ marginTop: '-40px', marginBottom: 'auto' }}
														className='dest fluid '
														src={united}
													/>
												</div>
												<div class='card-body'>
													<h5 class='card-title'>{t('destination.title5')}</h5>
												</div>
											</div>
										</div>
										<div className='swiper-slide'>
											<div class='card'>
												<div className='img-area'>
													<img
														style={{ marginTop: '-10px', marginBottom: 'auto' }}
														className='dest fluid '
														src={sabah}
													/>
												</div>
												<div class='card-body'>
													<h5 class='card-title'>{t('destination.title6')}</h5>
												</div>
											</div>
										</div>
										<div className='swiper-slide'>
											<div class='card'>
												<div className='img-area'>
													<img
														style={{ marginTop: '-20px', marginBottom: 'auto' }}
														className='dest fluid  '
														src={singapore}
													/>
												</div>
												<div class='card-body'>
													<h5 class='card-title'>{t('destination.title7')}</h5>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='swiper-pagination'></div>
							</div>
						</div>
					)}
				</div>
			</section>

			<FooterContactUsItem
				title='Shipping Consultation'
				btnTxt='Enquire'
				color='orange'
			/>
		</>
	)
}

export default Destination2
