import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import BannerItem from '../../component/BannerItem/BannerItem'
import useWindowDimensions from '../../useWindowDimensions'
import banner from '../../images/program/banner.png'
import './style.less'
import btn from '../../images/program/btn.svg'
import { Col, Row } from 'antd'
import icon from '../../images/program/Icon.svg'
import sideimg from '../../images/program/sideimg.png'
import icon1 from '../../images/program/icon1.png'
import icon2 from '../../images/program/icon2.png'
import icon3 from '../../images/program/icon3.png'
import icon4 from '../../images/program/icon4.png'
import mobile834 from '../../images/program/mobile834.png'

import StepComponent from '../../component/StepComponent/StepComponent'
import WOW from 'wowjs'
import FooterContactUsItem from '../../component/FooterContactUsItem/FooterContactUsItem'
const Program = (props) => {
	const { t } = useTranslation()
	const { height, width } = useWindowDimensions()

	useEffect(() => {
		window.scroll(0, 0)
		document.title = 'Wyngs - Program'
		new WOW.WOW({
			live: false,
		}).init()
	}, [])

	return (
		<>
			<BannerItem
				img={width > 835 ? banner : mobile834}
				title={t('program.title')}
				color='white'
			/>
			<section className='prgram_sec1 wow fadeIn d-f j-c f-c a-c'>
				<div className='title'>{t('program.title')}</div>
				<div className='desc'>{t('program.desc')}</div>
				<div className='listContainer'>
					<div className='d-f'>
						<div className='imgContainer'>
							<img className='logo fluid' src={btn} />
						</div>
						<div className='desc1'>{t('program.desc1')}</div>
					</div>
					<div className='d-f mt'>
						<div className='imgContainer'>
							<img className='logo fluid' src={btn} />
						</div>
						<div className='desc1'>{t('program.desc1')}</div>
					</div>
				</div>
			</section>
			<section className='prgram_sec2 wow fadeIn'>
				<Row>
					<Col lg={11}>
						<div className='titleContainer'>
							<div>
								<img className='logo fluid' src={icon} />
							</div>
							<div className='title'>{t('program.title')}</div>
						</div>
						<div className='title'>{t('program.hereHowItWorks')}</div>
						<StepComponent
							icon={icon1}
							Count={t('program.Step 1')}
							title={t('program.Sign up on WYNGS')}
							desc={t(
								'program.Sign up for the WYNGS Referral Program on our website.'
							)}
						/>
						<StepComponent
							icon={icon2}
							Count={t('program.Step 2')}
							title={t('program.step2Title')}
							desc={t('program.step2Desc')}
						/>
						<StepComponent
							icon={icon3}
							Count={t('program.Step 3')}
							title={t('program.step3Title')}
							desc={t('program.step3Desc')}
						/>
						<StepComponent
							icon={icon4}
							Count={t('program.Step 4')}
							title={t('program.step4Title')}
							desc={t('program.step4Desc')}
						/>
					</Col>
					<Col lg={13}>
						<div className='d-f a-c j-c h-f f-c'>
							<div className='imgContainer '>
								<img src={sideimg} className='fluid' />
							</div>
						</div>
					</Col>
				</Row>
			</section>
			<section className='prgram_sec3 wow fadeIn'>
				<div className='line'></div>
				<div className='desc'>{t('program.desc3')}</div>
				<div className='desc'>{t('program.desc4')}</div>
				<div className='desc'>{t('program.desc5')}</div>
			</section>

			<FooterContactUsItem
				title='Shipping Consultation'
				btnTxt='Enquire'
				color='orange'
			/>
		</>
	)
}

export default Program
