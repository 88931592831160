import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { I18nextProvider } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n.use(LanguageDetector).init({
	resources: {
		en: { translation: require('./locales/en.json') },
		ml: { translation: require('./locales/ml.json') },
		md: { translation: require('./locales/md.json') },
		dt: { translation: require('./locales/dt.json') },
	},
	fallbackLng: 'en', // Default language if translation not available
	debug: true, // Set to true for debugging
	interpolation: {
		escapeValue: false, // React already escapes variables, so no need to escape again
	},
})

ReactDOM.render(
	<I18nextProvider i18n={i18n}>
		<App />
	</I18nextProvider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
