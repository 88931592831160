import React from 'react'
import './style.css'

const Button = (props) => {
	console.log(props)
	return (
		<div
			onClick={props.onClick}
			// style={props.color ? { background: props.color, color: 'black' } : {}}
			style={props.small ? { fontSize: '1.4em' } : null}
			className={props.ogFull ? 'ogFullCta' : props.orange ? 'orgBtn' : 'cta'}
		>
			{props.title}
		</div>
	)
}

export default Button
