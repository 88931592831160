import { Col, Row } from 'antd'
import React from 'react'
import claim from '../../images/aboutus/06 - Claim B.png'
import './style.less'
import { useTranslation } from 'react-i18next'
import BannerItem from '../../component/BannerItem/BannerItem'
import useWindowDimensions from '../../useWindowDimensions'
import banner from '../../images/business/banner.png'
import create from '../../images/business/create.png'
import Swiper, { Autoplay, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
import { useEffect } from 'react'
import WOW from 'wowjs'
import ic1 from '../../images/business/ic1.png'
import ic2 from '../../images/business/ic2.png'
import ic3 from '../../images/business/ic3.png'
import ic4 from '../../images/business/ic4.png'
import ic5 from '../../images/business/ic5.png'
import ic6 from '../../images/business/ic6.png'
import ic7 from '../../images/business/ic7.png'
import ic8 from '../../images/business/ic8.png'

import icon1 from '../../images/business/icon1.png'
import icon2 from '../../images/business/icon2.png'
import icon3 from '../../images/business/icon3.png'
import icon4 from '../../images/business/icon4.png'
import icon5 from '../../images/business/icon5.png'
import icon6 from '../../images/business/icon6.png'
import icon7 from '../../images/business/icon7.png'
import icon8 from '../../images/business/icon8.png'
import FooterContactUsItem from '../../component/FooterContactUsItem/FooterContactUsItem'
Swiper.use([Autoplay, Pagination])

function Business_Benefit() {
	const { t } = useTranslation()
	const { height, width } = useWindowDimensions()

	useEffect(() => {
		window.scroll(0, 0)
		document.title = 'Wyngs - Business Beneifts'
		new WOW.WOW({
			live: false,
		}).init()

		const swiper2 = new Swiper('.business_sec2-swiper', {
			slidesPerView: 1,
			spaceBetween: 10,
			Autoplay: true,
			pagination: {
				el: '.business_sec1 .swiper-pagination',
				clickable: true,
			},
		})
	}, [])

	return (
		<>
			<BannerItem
				img={width > 640 ? banner : banner}
				title={t('business.title')}
				color='white'
			/>

			<session className='business_sec1 d-f j-c a-c f-c'>
				<div className='GbtitleContainer'>
					<div>
						<img className='fluid' src={create} />
					</div>
					<div className='txt'>{t('business.desc1')}</div>
				</div>
				<div className='title'>{t('business.title')}</div>
				<div className='desc'>{t('business.desc')}</div>

				{width >= 1024 ? (
					<>
						<div className='boxcontainer d-f j-c a-c'>
							<Row>
								<Col lg={12}>
									<div className='card'>
										<Row>
											<Col lg={12}>
												{/* <div className='logocontainer' */}
												<img className='logo fluid' src={ic1} />

												<div className='text'>{t('business.desc2')}</div>
											</Col>
											<Col lg={12}>
												<div className='strength imgContainer'>
													<img className=' fluid' src={icon1} />
												</div>
											</Col>
										</Row>
									</div>

									<div className='card'>
										<Row>
											<Col lg={12}>
												<img className='logo fluid' src={ic3} />
												<div className='text'>{t('business.desc3')}</div>
											</Col>
											<Col lg={12}>
												<div className='imgContainer'>
													<img className='strength fluid' src={icon3} />
												</div>
											</Col>
										</Row>
									</div>

									<div className='card'>
										<Row>
											<Col lg={12}>
												<img className='logo fluid' src={ic5} />
												<div className='text'>{t('business.desc4')}</div>
											</Col>
											<Col lg={12}>
												<div className='imgContainer'>
													<img className='strength fluid' src={icon5} />
												</div>
											</Col>
										</Row>
									</div>

									<div className='card'>
										<Row>
											<Col lg={12}>
												<img className='logo fluid' src={ic7} />
												<div className='text'>{t('business.desc5')}</div>
											</Col>
											<Col lg={12}>
												<div className='imgContainer'>
													<img className='strength fluid' src={icon7} />
												</div>
											</Col>
										</Row>
									</div>
								</Col>

								<Col lg={12}>
									<div className='card'>
										<Row>
											<Col lg={12}>
												<img className='logo fluid' src={ic2} />
												<div className='text'>{t('business.desc6')}</div>
											</Col>
											<Col lg={12}>
												<div className='imgContainer'>
													<img className='strength fluid' src={icon2} />
												</div>
											</Col>
										</Row>
									</div>

									<div className='card'>
										<Row>
											<Col lg={12}>
												<img className='logo fluid' src={ic4} />
												<div className='text'>{t('business.desc7')}</div>
											</Col>
											<Col lg={12}>
												<div className='imgContainer'>
													<img className='strength fluid' src={icon4} />
												</div>
											</Col>
										</Row>
									</div>

									<div className='card'>
										<Row>
											<Col lg={12}>
												<img className='logo fluid' src={ic6} />
												<div className='text'>{t('business.desc8')}</div>
											</Col>
											<Col lg={12}>
												<div className='imgContainer'>
													<img className='strength fluid' src={icon6} />
												</div>
											</Col>
										</Row>
									</div>

									<div className='card'>
										<Row>
											<Col lg={12}>
												<img className='logo fluid' src={ic8} />
												<div className='text'>{t('business.desc9')}</div>
											</Col>
											<Col lg={12}>
												<div className='imgContainer'>
													<img className='strength fluid' src={icon8} />
												</div>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</div>
					</>
				) : (
					<>
						<div className='boxcontainer p-r d-f f-c j-c a-c'>
							<div className='swiper-slide'>
								<div className='card'>
									<Row>
										<Col lg={12}>
											{/* <div className='logocontainer' */}
											<img className='logo fluid' src={ic1} />

											<div className='text'>{t('business.desc2')}</div>
										</Col>
										<Col lg={12}>
											<div className='strength imgContainer'>
												<img className=' fluid' src={icon1} />
											</div>
										</Col>
									</Row>
								</div>
							</div>
							<div className='swiper-slide'>
								<div className='card'>
									<Row>
										<Col lg={12}>
											<img className='logo fluid' src={ic3} />
											<div className='text'>{t('business.desc3')}</div>
										</Col>
										<Col lg={12}>
											<div className='imgContainer'>
												<img className='strength fluid' src={icon3} />
											</div>
										</Col>
									</Row>
								</div>
							</div>
							<div className='swiper-slide'>
								<div className='card'>
									<Row>
										<Col lg={12}>
											<img className='logo fluid' src={ic5} />
											<div className='text'>{t('business.desc4')}</div>
										</Col>
										<Col lg={12}>
											<div className='imgContainer'>
												<img className='strength fluid' src={icon5} />
											</div>
										</Col>
									</Row>
								</div>
							</div>
							<div className='swiper-slide'>
								<div className='card'>
									<Row>
										<Col lg={12}>
											<img className='logo fluid' src={ic7} />
											<div className='text'>{t('business.desc5')}</div>
										</Col>
										<Col lg={12}>
											<div className='imgContainer'>
												<img className='strength fluid' src={icon7} />
											</div>
										</Col>
									</Row>
								</div>
							</div>
							<div className='swiper-slide'>
								<div className='card'>
									<Row>
										<Col lg={12}>
											<img className='logo fluid' src={ic2} />
											<div className='text'>{t('business.desc6')}</div>
										</Col>
										<Col lg={12}>
											<div className='imgContainer'>
												<img className='strength fluid' src={icon2} />
											</div>
										</Col>
									</Row>
								</div>
							</div>
							<div className='swiper-slide'>
								<div className='card'>
									<Row>
										<Col lg={12}>
											<img className='logo fluid' src={ic4} />
											<div className='text'>{t('business.desc7')}</div>
										</Col>
										<Col lg={12}>
											<div className='imgContainer'>
												<img className='strength fluid' src={icon4} />
											</div>
										</Col>
									</Row>
								</div>
							</div>
							<div className='swiper-slide'>
								<div className='card'>
									<Row>
										<Col lg={12}>
											<img className='logo fluid' src={ic6} />
											<div className='text'>{t('business.desc8')}</div>
										</Col>
										<Col lg={12}>
											<div className='imgContainer'>
												<img className='strength fluid' src={icon6} />
											</div>
										</Col>
									</Row>
								</div>
							</div>
							<div className='swiper-slide'>
								<div className='card'>
									<Row>
										<Col lg={12}>
											<img className='logo fluid' src={ic8} />
											<div className='text'>{t('business.desc9')}</div>
										</Col>
										<Col lg={12}>
											<div className='imgContainer'>
												<img className='strength fluid' src={icon8} />
											</div>
										</Col>
									</Row>
								</div>
							</div>
						</div>
					</>
				)}
			</session>

			<FooterContactUsItem
				title={t('For Bussiness')}
				btnTxt='Contact me'
				color='orange'
			/>
		</>
	)
}

export default Business_Benefit
