import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import BannerItem from '../../component/BannerItem/BannerItem'
import useWindowDimensions from '../../useWindowDimensions'
import banner from '../../images/service/banner.png'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import create from '../../images/service/create.png'
import dangererous from '../../images/service/Dangerous.png'
import pickup from '../../images/service/Pickup.png'
import cargo from '../../images/service/cargo.png'
import click from '../../images/service/click.png'
import dangererous_logo from '../../images/service/Dangerous_logo.png'
import pickup_logo from '../../images/service/pickup_logo.png'
import cargo_logo from '../../images/service/cargo_logo.png'
import repacking_logo from '../../images/service/repacking_logo.png'
import pack_logo from '../../images/service/pack_logo.png'
import insurance_logo from '../../images/service/insurance_logo.png'
import scan from '../../images/service/scan.png'
import WOW from 'wowjs'

import r1 from '../../images/service/r1.png'
import r2 from '../../images/service/r2.png'
import r3 from '../../images/service/r3.png'

import './style.less'
import { Carousel, Col, Row } from 'antd'
import FooterContactUsItem from '../../component/FooterContactUsItem/FooterContactUsItem'

const Service = (props) => {
	const { t } = useTranslation()
	const { height, width } = useWindowDimensions()

	useEffect(() => {
		window.scroll(0, 0)
		document.title = 'Wyngs - Service'
		new WOW.WOW({
			live: false,
		}).init()
	}, [])

	return (
		<>
			<BannerItem
				img={width > 640 ? banner : banner}
				title={t('Service.title')}
				color='white'
			/>
			<section className='service_sec1 wow fadeIn d-f j-c a-c f-c'>
				<div className='GbtitleContainer'>
					<div>
						<img className='fluid' src={create} />
					</div>
					<div className='txt'>{t('Service.title1')}</div>
				</div>
				<div className='title'>{t('Service.title1')}</div>

				{width >= 1023 ? (
					<>
						<div className='flex-container d-f j-c a-c f-r'>
							<div className='card h-100 d-f'>
								<div className='logo'>
									<img className='logo1 fluid' src={repacking_logo} />
								</div>
								<div className='title1'>{t('Service.title2')}</div>
								<div className='desc'> {t('Service.desc2')} </div>

								<div className='box d-f'>
									<div className='content '>
										<div className='first'>
											<img className=' fluid' src={r1} />
										</div>
										<div className='text'>5kg box : 17 x 26 x 38 (cm)</div>
									</div>
									<div className='content '>
										<div className='second'>
											<img className=' fluid' src={r2} />{' '}
										</div>
										<div className='text'>10kg box : 26 x 27 x 52 (cm)</div>
									</div>
									<div className='content '>
										<div className='third'>
											<img className='fluid' src={r3} />{' '}
										</div>
										<div className='text'> 25kg box : 44 x 46 x 45 (cm)</div>
									</div>
								</div>
							</div>

							<div className='card h-100 d-f'>
								<div className='logo'>
									<img className='logo1 fluid' src={insurance_logo} />
								</div>
								<div className='title1'>{t('Service.title3')}</div>
								<div className='desc'>{t('Service.desc3')} </div>

								<div className='d-f first1'>
									<div className=' imgcontainer'>
										<img className='fluid' src={click} />{' '}
									</div>
									<div className='text'>
										{t('Service.l1')}
										<br /> {t('Service.l2')}
									</div>
								</div>
							</div>

							<div className='card h-100 d-f'>
								<div className='logo'>
									<img className='logo1 fluid' src={pack_logo} />
								</div>
								<div className='title1'> {t('Service.title4')}</div>
								<div className='desc'>{t('Service.desc4')}</div>

								<div className='ship_box'>
									<img className='fluid' src={scan} />
								</div>
							</div>

							<div className='card d-f'>
								<div className='logo'>
									<img className='logo1 fluid' src={pickup_logo} />
								</div>
								<div className='title1'>{t('Service.title5')}</div>
								<div className='desc'>{t('Service.desc5')} </div>

								<div className='ship_box'>
									<img className='fluid' src={pickup} />
								</div>
							</div>

							<div className='card d-f'>
								<div className='logo'>
									<img className='logo1 fluid' src={cargo_logo} />
								</div>
								<div className='title1'> {t('Service.title6')} </div>
								<div className='desc'>{t('Service.desc6')} </div>

								<div className='ship_box'>
									<img className='fluid' src={cargo} />
								</div>
							</div>

							<div className='card d-f'>
								<div className='logo'>
									<img className='logo1 fluid' src={dangererous_logo} />
								</div>
								<div className='title1'> {t('Service.title7')} </div>
								<div className='desc'>{t('Service.desc7')}</div>
								<div className='ship_box'>
									<img className='fluid' src={dangererous} />
								</div>
							</div>
						</div>
					</>
				) : (
					<>
						<div className='service_sec1 d-f f-c j-c a-c'>
							<div className='flex-container d-f j-c a-c'>
								<div className='card d-f'>
									<div className='logo'>
										<img className='logo1 fluid' src={repacking_logo} />
									</div>
									<div className='title1'>{t('Service.title2')}</div>
									<div className='desc'> {t('Service.desc2')} </div>

									<div className='box '>
										<div className='content d-f'>
											<div className='first'>
												<img className='fluid' src={r1} />
											</div>
											5kg box : 17 x 26 x 38 (cm)
										</div>
										<div className='content d-f'>
											<div className='second'>
												<img className='fluid' src={r2} />{' '}
											</div>
											<div className='text'>10kg box : 26 x 27 x 52 (cm)</div>
										</div>
										<div className='content d-f'>
											<div className='third'>
												<img className='fluid' src={r3} />{' '}
											</div>
											<div className='text'> 25kg box : 44 x 46 x 45 (cm)</div>
										</div>
									</div>
								</div>

								<div className='card d-f'>
									<div className='logo'>
										<img className='logo1 fluid' src={insurance_logo} />
									</div>
									<div className='title1'>{t('Service.title3')}</div>
									<div className='desc'>{t('Service.desc3')} </div>

									<div className='d-f first1'>
										<div className=' imgcontainer'>
											<img className='fluid' src={click} />{' '}
										</div>
										<div className='desc'>
											{t('Service.l1')}
											<br /> {t('Service.l2')}
										</div>
									</div>
								</div>

								<div className='card d-f'>
									<div className='logo'>
										<img className='logo1 fluid' src={pack_logo} />
									</div>
									<div className='title1'> {t('Service.title4')}</div>
									<div className='desc'>{t('Service.desc4')}</div>

									<div className='ship_box'>
										<img className='fluid' src={scan} />
									</div>
								</div>

								<div className='card d-f'>
									<div className='logo'>
										<img className=' logo1 fluid' src={pickup_logo} />
									</div>
									<div className='title1'>{t('Service.title5')}</div>
									<div className='desc'>{t('Service.desc5')} </div>

									<div className='ship_box'>
										<img className='fluid' src={pickup} />
									</div>
								</div>

								<div className='card d-f'>
									<div className='logo'>
										<img className='logo1 fluid' src={cargo_logo} />
									</div>
									<div className='title1'> {t('Service.title6')} </div>
									<div className='desc'>{t('Service.desc6')} </div>

									<div className='ship_box'>
										<img className='fluid' src={cargo} />
									</div>
								</div>

								<div className='card d-f'>
									<div className='logo'>
										<img className='logo1 fluid' src={dangererous_logo} />
									</div>
									<div className='title1'> {t('Service.title7')} </div>
									<div className='desc'>{t('Service.desc7')}</div>
									<div className='ship_box'>
										<img className='fluid' src={dangererous} />
									</div>
								</div>
							</div>
						</div>
					</>
				)}

				<div className='container d-f j-c a-c f-c'>
					<div className='box2 d-f'>
						<div className='logo'>
							<img className='logo1 fluid' src={repacking_logo} />
							<div className='title1'> {t('Service.title8')} </div>
							<div className='text'>{t('Service.desc8')}</div>
						</div>
					</div>
				</div>
			</section>

			<FooterContactUsItem
				title='Shipping Consultation'
				btnTxt='Enquire'
				color='orange'
			/>
		</>
	)
}

export default Service
