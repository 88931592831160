import React from 'react'
import './style.less'

import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const FooterContactUsItem = (props) => {
	const { t } = useTranslation()

	const { color, title, desc, btnTxt } = props
	const history = useHistory()

	return (
		<div className={`footerContactItem ${color}`}>
			<div>
				<div className='title'>{title}</div>
				<div className='desc'>{t('Get in touch with us to know more.')}</div>
			</div>
			<div className='d-f f-c j-c'>
				<button
					onClick={() => (window.location.href = 'https://me-qr.com/re9utMtj')}
				>
					{t('Contact Me')}
				</button>
			</div>
		</div>
	)
}

export default FooterContactUsItem
