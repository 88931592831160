import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import BannerItem from '../../component/BannerItem/BannerItem'
import useWindowDimensions from '../../useWindowDimensions'
import banner from '../../images/ecommerce/banner.png'
import './style.less'
import btn from '../../images/program/btn.svg'
import search from '../../images/ecommerce/search.png'
import WOW from 'wowjs'
import mobile834 from '../../images/ecommerce/mobile834.png'
import FooterContactUsItem from '../../component/FooterContactUsItem/FooterContactUsItem'
import urldata from '../../urldata'
import Button from '../../component/Button/Button'

const EcommercePlatform = (props) => {
	const { t } = useTranslation()
	const { height, width } = useWindowDimensions()

	useEffect(() => {
		window.scroll(0, 0)
		document.title = 'Wyngs - Ecommerce Platform'
		new WOW.WOW({
			live: false,
		}).init()
	}, [])

	const downloadFile = (fileName) => {
		const filePath = `public/docs/${fileName}.docx` // Adjust the path based on your folder structure
		const link = document.createElement('a')
		link.href = urldata + filePath
		link.download = fileName
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	return (
		<>
			<BannerItem
				img={width > 835 ? banner : mobile834}
				title={t('ecommerce.title')}
				color='white'
			/>
			<section className='ecommerce_sec1 wow fadeIn d-f j-c f-c a-c'>
				<div className='title'>{t('ecommerce.title')}</div>
				<div className='listContainer'>
					<div className='d-f'>
						<div className='imgContainer'>
							<img className='logo fluid' src={btn} />
						</div>
						<div className='desc1'>{t('ecommerce.l1')}</div>
					</div>
					<div className='d-f mt'>
						<div className='imgContainer'>
							<img className='logo fluid' src={btn} />
						</div>
						<div className='desc1'>{t('ecommerce.l2')}</div>
					</div>
					<div className='d-f mt'>
						<div className='imgContainer'>
							<img className='logo fluid' src={btn} />
						</div>
						<div className='desc1'>{t('ecommerce.l3')}</div>
					</div>
				</div>
			</section>
			{/* <section className='ecommerce_sec2 wow fadeIn d-f j-c f-c a-c'>
				<div>
					<img src={search} className='fluid' />
				</div>
			</section> */}
			<section className='ecommerce_sec3 wow fadeIn d-f j-c f-c a-c'>
				<div className='title'>{t('ecommerce.apiDocumnetaion')}</div>

				<div style={{ paddingBottom: '3em', paddingTop: '1em' }}>
					<Button
						small={true}
						title='Account Login Api'
						onClick={() => downloadFile('document1')}
					></Button>
					&nbsp;&nbsp;
					<Button
						small={true}
						title='Create Shipment API '
						onClick={() => downloadFile('document2')}
					></Button>
					&nbsp;&nbsp;
					<Button
						small={true}
						title='Tracking API '
						onClick={() => downloadFile('document3')}
					></Button>
				</div>
			</section>

			<FooterContactUsItem
				title='Shipping Consultation'
				btnTxt='Enquire'
				color='orange'
			/>
		</>
	)
}

export default EcommercePlatform
