import React from 'react'
import { Row, Col, Image } from 'antd'
import makeindia from '../../images/footer/makeindia.png'
import linkdin from '../../images/footer/linkdin.png'
import useWindowDimensions from './../../useWindowDimensions'
import logo from '../../images/footer1/Wyngs assets-01.png'
import facebook from '../../images/footer1/facebook.png'
import insta from '../../images/footer1/insta.png'
import twitter from '../../images/footer1/twitter.png'
import { Link } from 'react-router-dom'

import './style.less'
import { useTranslation } from 'react-i18next'

const Footer1 = () => {
	const { t } = useTranslation()
	const { height, width } = useWindowDimensions()
	const linkStyle = {
		paddingTop: '1em',
		// margin: "0.5em",
		textDecoration: 'none',
		color: '  #1A202C',
	}
	const currentYear = new Date()?.getFullYear()

	return (
		<div className='footerContainer'>
			<Row justify='center' className='secondrow'>
				<Col lg={9} sm={24} className='ft-1'>
					<div className='logoContainer'>
						<img className='fluid' src={logo} />
					</div>
					<div className='desc'>
						<p>{t('footer.title')} </p>
					</div>
					<div className='footer-icons'>
						<div className='social-div '>
							<img
								onClick={() =>
									window.open('https://www.facebook.com', '_blank')
								}
								className='facebook fluid'
								src={facebook}
							/>
						</div>

						<div className='social-div '>
							<img
								onClick={() => window.open('https://www.twitter.com', '_blank')}
								className='fluid'
								src={twitter}
							/>
						</div>

						<div className='social-div '>
							<img
								onClick={() =>
									window.open('https://www.instagram.com', '_blank')
								}
								className='fluid'
								src={insta}
							/>
						</div>
					</div>
				</Col>
				<Col lg={5} xs={12} className='ft-2'>
					<div className='head'>
						<Link to='/service' style={linkStyle}>
							{t('footer.list1')}
						</Link>
					</div>
					<h4>
						<Link to='/transit' style={linkStyle}>
							{t('homepage.Track & Trace')}
						</Link>
					</h4>

					<h4>
						<Link to='/dispute-center' style={linkStyle}>
							{t('footer.Dispute Centre')}
						</Link>
					</h4>

					<h4>
						<Link to='/support' style={linkStyle}>
							{t('Support?')}
						</Link>
					</h4>
				</Col>
				<Col lg={5} xs={12} className='ft-3'>
					<div className='head'>
						<Link to='' style={linkStyle}>
							{t('Join Us')}
						</Link>
					</div>
					<h4>
						<Link to='/business' style={linkStyle}>
							{t('Wyngs for Business')}
						</Link>
					</h4>

					<h4>
						<Link to='/ecommercePlatform' style={linkStyle}>
							{t('Intergration with Ecommerce')}
						</Link>
					</h4>

					<h4>
						<Link to='/program' style={linkStyle}>
							{t('Programs')}
						</Link>
					</h4>
					<h4>
						<Link to='/destination' style={linkStyle}>
							{t('destination.title')}
						</Link>
					</h4>
				</Col>
				<Col lg={5} sm={24} className='ft-4'>
					<div className='head'>
						<Link to='' style={linkStyle}>
							{t('Info')}
						</Link>
					</div>
					<h4>
						<Link to='/about' style={linkStyle}>
							{t('about.title')}
						</Link>
					</h4>

					<h4>
						<Link to='/faq' style={linkStyle}>
							{t('FAQ.title')}
						</Link>
					</h4>
				</Col>
			</Row>
			<hr className='hr hr-blurry' />
			<Row className='firstrow'>
				<Col lg={18} sm={24}>
					<div className='left'>{t('copyRight')?.replace('2022',currentYear)}</div>
				</Col>
				<Col lg={6} sm={24}>
					<div className='right'>
						<h6>
							<Link to='' style={linkStyle}>
								{t('Privacy & Policy')}
							</Link>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<Link to='' style={linkStyle}>
								{t('Term & Conditions')}
							</Link>
						</h6>
					</div>{' '}
				</Col>
			</Row>
		</div>
	)
}

export default Footer1
